import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon/Icon'
import Tooltip from '../Tooltip/Tooltip'

type MenuItemColor = 'error' | 'primary' | 'success' | 'warning' | 'info'

const MenuItemPrefix = styled.div`
  padding-right: 10px;
`

const MenuItemSuffix = styled.div`
  padding-left: 10px;
  color: inherit;
`
const MenuItemContainer = styled.button<{ color?: MenuItemColor }>`
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({disabled}) => (disabled ? '0.5' : '1')};
  border: none;
  width: 100%;
  background-color: ${({theme}) => theme.background};
  color: ${({theme, color = 'textPrimary'}) => {
    if (color === 'textPrimary') {
        return theme.textPrimary
    } else {
        return (theme as any)?.[color]
    }
}};
  &:hover {
    background-color: ${({theme, color = 'background'}) =>
    (theme as any)?.[color + '10']};
  }
  border-radius: 10px;
  ${MenuItemPrefix} *, ${MenuItemSuffix} * {
    color: ${({theme, color = 'textPrimary'}) => {
    return (theme as any)?.[color]
}}!important;
  }
`

const Container = styled.div`
  background-color: ${({theme}) => theme.background};
  border-radius: 15px;
  min-width: 150px;
  padding: 10px;
`

const MenuItemLabel = styled.p`
  margin: 0;
  display: flex;
  flex: 1;
  white-space: nowrap;
`
const Menu = (props: any) => {
    return <Container {...props}>{props.children}</Container>
}

export interface MenuItemProps {
    prefix?: any
    suffix?: any
    children?: any
    onClick?: (value?: any) => any
    color?: MenuItemColor
    isLoading?: boolean
    disabled?: boolean
    tooltip?: any
}

const MenuItem = ({
                      prefix,
                      suffix,
                      children,
                      onClick,
                      color,
                      isLoading,
                      disabled,
                      tooltip
                  }: MenuItemProps) => {
    return (
        <Tooltip content={tooltip}>
            <MenuItemContainer
                onClick={onClick}
                color={color}
                disabled={isLoading || disabled}
            >
                {isLoading ? (
                    <MenuItemPrefix>
                        <Icon icon="loading" spin/>
                    </MenuItemPrefix>
                ) : (
                    <>{!!prefix && <MenuItemPrefix>{prefix}</MenuItemPrefix>}</>
                )}
                <MenuItemLabel>{children}</MenuItemLabel>
                <>{!!suffix && <MenuItemSuffix>{suffix}</MenuItemSuffix>}</>
            </MenuItemContainer>
        </Tooltip>
    )
}
Menu.Item = MenuItem
export default Menu
