import React, { useContext, useEffect, useState } from 'react'
import Button from '../common/Button/Button'
import logo from '../../assets/logo.png'
import Row from '../common/Row/Row'
import {
  Background,
  Container,
  Content,
  CustomAvatar,
  Logo,
} from './Header.style'
import ModalRegister from '../../modals/ModalRegister'
import ModalLogin from '../../modals/ModalLogin'
import { useModal } from '../common/Modal/Modal'
import { auth } from '../../firebase'
import AuthContext from '../../contexts/AuthContext'
import Popper from '../common/Popper/Popper'
import Menu, { MenuItemProps } from '../common/Menu/Menu'
import { useNavigate } from 'react-router-dom'
import Avatar from '../common/Avatar/Avatar'
import Icon from '../common/Icon/Icon'
import { signOut } from 'firebase/auth'
import { useTranslation } from 'react-i18next'
import Flag from '../common/Flag/Flag'
import { CountriesISO } from '../../utils'
import { useMediaQuery } from 'react-responsive'
import useScroll from '../../hooks/useScroll'

const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const modalLogin = useModal()
  const modalRegister = useModal()
  const authCtx = useContext(AuthContext)
  const loggedIn = !!authCtx?.user

  const scrollPosition = useScroll()

  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng)
  }

  const languageList: MenuItemProps[] = [
    {
      prefix: <Flag flag="en" />,
      children: 'English',
      suffix: i18n.language === 'en' ? <Icon icon="check" /> : '',
      onClick: () => changeLanguage('en'),
    },
    {
      prefix: <Flag flag="fr" />,
      children: 'Français',
      suffix: i18n.language === 'fr' ? <Icon icon="check" /> : '',
      onClick: () => changeLanguage('fr'),
    },
  ]

  return (
    <Container>
      <Background scrollPosition={scrollPosition} />
      <Content>
        <Logo src={logo} onClick={() => navigate('/')} />
        {isMobile ? (
          <Popper
            placement="bottom-end"
            popContent={
              <Menu>
                {!loggedIn ? (
                  <>
                    <Menu.Item onClick={() => navigate('/games')}>
                      {t('header.games')}
                    </Menu.Item>
                    <Menu.Item onClick={() => modalLogin.open()}>
                      {t('header.login')}
                    </Menu.Item>
                    <Menu.Item onClick={() => modalRegister.open()}>
                      {t('header.register')}
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Item
                      onClick={() => navigate('/profil')}
                      prefix={
                        <Avatar size={14} src={authCtx?.user?.photoURL || ''} />
                      }
                    >
                      Profile
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate('/games')}>
                      {t('header.games')}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/profil/sessions')}
                      prefix={<Icon icon="bowling" />}
                    >
                      Mes sessions
                    </Menu.Item>

                    <Menu.Item
                      color="error"
                      prefix={<Icon icon="sign-out" />}
                      onClick={() => signOut(auth)}
                    >
                      Se deconnecter
                    </Menu.Item>
                  </>
                )}
              </Menu>
            }
          >
            <Icon icon="menu-burger" />
          </Popper>
        ) : (
          <Row flex="1" alignItems="center" justifyContent="flex-end">
            <Button
              margin="0 10px 0 0"
              onClick={() => navigate('/profil/sessions')}
            >
              {t('header.sessions')}
            </Button>
            <Button margin="0 10px 0 0" onClick={() => navigate('/games')}>
              {t('header.games')}
            </Button>
            <Button margin="0 10px 0 0" actions={languageList}>
              <Flag flag={i18n.language as CountriesISO} />
            </Button>
            {!loggedIn ? (
              <>
                <Button
                  color="primary"
                  margin="0 10px 0 0"
                  onClick={() => modalLogin.open()}
                >
                  {t('header.login')}
                </Button>
                <Button color="primary" onClick={() => modalRegister.open()}>
                  {t('header.register')}
                </Button>
              </>
            ) : (
              <Popper
                placement="bottom-end"
                popContent={
                  <Menu>
                    <Menu.Item
                      onClick={() => navigate('/profil')}
                      prefix={
                        <Avatar size={14} src={authCtx?.user?.photoURL || ''} />
                      }
                    >
                      Profile
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/profil/sessions')}
                      prefix={<Icon icon="bowling" />}
                    >
                      Mes sessions
                    </Menu.Item>
                    <Menu.Item
                      color="error"
                      prefix={<Icon icon="sign-out" />}
                      onClick={() => signOut(auth)}
                    >
                      Se deconnecter
                    </Menu.Item>
                  </Menu>
                }
              >
                <CustomAvatar size={45} src={authCtx?.user?.photoURL || ''} />
              </Popper>
            )}
          </Row>
        )}
      </Content>
      {!loggedIn && (
        <>
          <ModalLogin
            {...modalLogin}
            onGoRegister={() => {
              modalLogin.close()
              modalRegister.open()
            }}
          />
          <ModalRegister
            {...modalRegister}
            onGoLogin={() => {
              modalRegister.close()
              modalLogin.open()
            }}
          />
        </>
      )}
    </Container>
  )
}

export default Header
