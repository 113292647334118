import styled from 'styled-components'
import Button from '../common/Button/Button'
import Icon from '../common/Icon/Icon'

export const Container = styled.div`
  z-index: 10000;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`

export const ArrowIcon = styled(Icon)`
  transition: all 0.4s cubic-bezier(0.5, -0.01, 0.76, 1);
`

export const StartButton = styled(Button)`
  :hover {
    ${ArrowIcon} {
      transform: translateX(10px);
    }
  }
`
