import React from 'react'
import styled from 'styled-components'
import Row from '../common/Row/Row'
import logoWhite from '../../assets/logo-white.png'

export const Container = styled.div`
  padding: 10px;
  color: #ffffff;
  font-size: 13px;
  white-space: nowrap;
  margin-bottom: 20px;
`
export const Logo = styled.img`
  height: 20px;
  margin-left: 3px;
`
const PoweredBy = () => (
  <Container>
    <Row alignItems="center" justifyContent="center">
      Powered by
      <Logo src={logoWhite} />
    </Row>
  </Container>
)

export default PoweredBy
