import {
  addDoc,
  collection,
  doc,
  getFirestore,
  increment,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { useMutation } from 'react-query'

const ContactServices = {
  useNewContact: () => {
    return useMutation(async ({ email, message }: any) => {
      try {
        return await addDoc(collection(getFirestore(), 'contactRequestsers'), {
          date: new Date().getTime(),
          email: email,
          message: message,
        })
      } catch (error) {
        console.log('ERROR')
      }
    })
  },
}
export default ContactServices
