import React, { useEffect, useState } from 'react'
import Col from '../common/Col/Col'
import Text, { Title3 } from '../common/Text/Text'
import PoweredBy from '../PoweredBy/PoweredBy'
import { Container, ArrowIcon, StartButton } from './StartLive.style'
import UsersServices from '../../services/data/user'
import InputSelect from '../../components/common/Input/InputSelect/InputSelect'
import Button from '../../components/common/Button/Button'
import { useNavigate, useParams } from 'react-router-dom'

interface StartLiveProps {
  onStart: (v: any) => any
  isLoading: boolean
  activityType?: string
}

const StartLive = ({ onStart, isLoading, activityType }: StartLiveProps) => {
  const navigate = useNavigate()
  const user = UsersServices.useFetchUser()
  const params = useParams()
  const [currentAccount, setCurrentAccount] = useState(params.username)

  const goBack = () => {
    navigate(-1)
  }

  return (
    <Container>
      <Col
        flex="1"
        justifyContent="center"
        style={{ maxWidth: '300px' }}
        margin="auto"
      >
        <Title3>Before continue make sure that :</Title3>
        <Col alignItems="start" margin="5px 0">
          <Text textAlign="left" type="3">
            1 - The live is launch on tiktok
          </Text>
          <Text textAlign="left" type="3">
            2 - Select your Tiktok account
          </Text>
        </Col>
        <InputSelect
          name="socialAccount"
          label="Tiktok Account"
          value={currentAccount}
          onChange={(e) => setCurrentAccount(e)}
          options={user?.data?.socialAccounts.map((e: any) => {
            return { label: e.username, value: e.username }
          })}
        />
        <Col alignItems="start" margin="5px 0">
          <Text textAlign="left" type="3">
            3 - Launch the live on LicoGames
          </Text>
        </Col>
        <StartButton
          color="primary"
          suffix={<ArrowIcon icon="angle-small-right" />}
          isLoading={isLoading}
          onClick={(e) => {
            navigate(
              `/games/${activityType}/session/${params.sessionId}/${currentAccount}`
            )
            e.stopPropagation()
            onStart?.(e)
          }}
          expand
        >
          {"Let's go"}
        </StartButton>
        <Button onClick={goBack} expand margin={'10px 0'}>
          Cancel
        </Button>
      </Col>
      <PoweredBy />
    </Container>
  )
}

export default StartLive
