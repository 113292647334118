import React from 'react'
import styled from 'styled-components'

import { ToastContainer } from 'react-toastify'

const Container = styled(ToastContainer)`
  --toastify-icon-color-info: ${({ theme }) => theme.info};
  --toastify-icon-color-success: ${({ theme }) => theme.success};
  --toastify-icon-color-warning: ${({ theme }) => theme.warning};
  --toastify-icon-color-error: ${({ theme }) => theme.error};

  --toastify-color-progress-dark: ${({ theme }) => theme.textPrimary};
  --toastify-color-progress-info: ${({ theme }) => theme.info};
  --toastify-color-progress-success: ${({ theme }) => theme.success};
  --toastify-color-progress-warning: ${({ theme }) => theme.warning};
  --toastify-color-progress-error: ${({ theme }) => theme.error};

  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.background};
    box-shadow: 0px 10px 50px rgb(0 0 0 / 10%);
    border: 1px solid ${({ theme }) => theme.borderColor};
  }
  .Toastify__toast-body {
    color: ${({ theme }) => theme.textSecondary};
    font-size: 15px;
    font-weight: 500;
  }
  .Toastify__progress-bar {
  }
`
const Toast = () => {
  return (
    <Container
      position="bottom-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
    />
  )
}

export default Toast
