import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import Modal, { ModalProps } from '../components/common/Modal/Modal'
import { useTranslation } from 'react-i18next'
import Grid from '../components/common/Grid/Grid'
import Row from '../components/common/Row/Row'
import Button from '../components/common/Button/Button'
import InputFile from '../components/common/Input/InputFile/InputFile'
import Icon from '../components/common/Icon/Icon'
import AuthContext from '../contexts/AuthContext'
import GalleryServices from '../services/data/gallery'

const Content = styled.div`
  width: 600px;
  max-width: 100%;
`

const GalleryImage = styled.img<{ selected?: boolean }>`
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  border: ${({ selected, theme }) =>
    selected ? '4px solid ' + theme.primary : '4px solid transparent'};
`
const File = styled.div`
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
`

interface ModalFileManagerProps extends ModalProps {
  value?: string
  onChange?: (value: string) => any
}
const ModalFileManager = (props: ModalFileManagerProps) => {
  const authCtx = useContext(AuthContext)
  const { t } = useTranslation()
  const [selected, setSelected] = useState(props?.value || '')

  const assets = GalleryServices.useFetchUserGallery()
  const usePushToGallery = GalleryServices.usePushToGallery()

  const onFileClick = (e: React.MouseEvent, url: string) => {
    e.stopPropagation()
    setSelected?.(url)
  }
  const uploadFiles = (files: any) => {
    usePushToGallery.mutate(
      { files: files },
      { onSuccess: () => assets.refetch() }
    )
  }

  const onUseSelection = () => {
    props.onChange?.(encodeURI(selected))
    props.close?.()
  }
  return (
    <Modal
      {...props}
      title={t('modals.modalFileManager.title')}
      footer={
        <Row alignItems="center" justifyContent="flex-end">
          <InputFile
            prefix={<Icon icon="cloud-upload" />}
            name="newFile"
            label="Add new image"
            onFileChange={(files: any) => uploadFiles([...files])}
          />
          {selected && (
            <Button
              margin="0 0 0 10px"
              color="primary"
              onClick={() => onUseSelection()}
            >
              Use selection
            </Button>
          )}
        </Row>
      }
    >
      <Content>
        <Grid
          columns="4"
          responsive={{
            mobileS: 1,
            mobileM: 2,
            mobileL: 2,
            tablet: 2,
            laptop: 3,
            laptopL: 4,
          }}
          onClick={() => props.onChange?.('')}
        >
          {assets?.data?.map((file: any, index: number) => (
            <File key={index} onClick={(e) => onFileClick(e, file.url)}>
              <GalleryImage src={file.url} selected={file.url === selected} />
            </File>
          ))}
        </Grid>
      </Content>
    </Modal>
  )
}

export default ModalFileManager
