import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyAnZ9guPm5UGI-Sp48CylKShjHVbA57Azw',
  authDomain: 'licogames-f3ebf.firebaseapp.com',
  projectId: 'licogames-f3ebf',
  storageBucket: 'licogames-f3ebf.appspot.com',
  messagingSenderId: '954785519969',
  appId: '1:954785519969:web:cd009c09cff5904c668d20',
  measurementId: 'G-MFMRLJVH5S'
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

export {
  auth,
  db,
  app,
}
