import styled, { css, keyframes } from 'styled-components'
import Icon from '../Icon/Icon'

const FadeAnim = keyframes`
  from {
    opacity: 0;
  } 
  to {
    opacity: 0.5;
  }
`
export const Container = styled.div<{ isFullScreen?: boolean }>`
  position: relative;
  ${({ isFullScreen, theme }) => {
    return isFullScreen
      ? css`
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: ${theme.background};
        `
      : ''
  }}
`

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255 0.5);
`
export const LoadingState = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  color: ${({ theme }) => theme.textPrimary};
`
export const LoadingIcon = styled(Icon)`
  margin: 10px;
  font-size: 30px;
`
