import React, { forwardRef, useCallback, useState } from 'react'
import styled from 'styled-components'
import Icon from '../../Icon/Icon'
import Menu from '../../Menu/Menu'
import Popper from '../../Popper/Popper'
import {
  Asterisk,
  Container,
  FakeInput,
  Label,
  MenuItemLabel,
  MenuItemPrefix,
  Prefix,
  Suffix,
  ValueTag,
} from './InputSelect.style'
interface Option {
  prefix?: any
  label: any
  value: any
  renderValue?: any
}

interface InputSelectValueProps {
  value: any[]
  changeValue: (value: any) => void
  options: Option[]
  multiple: boolean
}
const InputSelectValue = ({
  value,
  changeValue,
  options = [],
  multiple,
}: InputSelectValueProps) => {
  const getValue = (v?: any[]) => {
    const current: any =
      options?.find((el) => {
        return multiple ? v?.includes(el.value) : el.value === value
      }) || ''
    return current?.renderValue ?? current?.label
  }
  return (
    <>
      {multiple ? (
        <>
          {value?.map((v, index) => {
            return (
              <ValueTag key={index} onClick={() => changeValue(v)}>
                {getValue([v])} <Icon icon="cross-small" />
              </ValueTag>
            )
          })}
        </>
      ) : (
        getValue(value)
      )}
    </>
  )
}

export interface InputSelectConfig {
  prefix?: any
  options: Option[]
  multiple?: boolean
  label: any
  required?: boolean
}

interface InputSelectProps extends InputSelectConfig {
  name: string
  value?: any
  onChange: (value?: any) => any
  hasError?: boolean
}
const InputSelect = (
  {
    name,
    label,
    value = [],
    onChange,
    prefix,
    options = [],
    multiple = false,
    required,
    hasError,
  }: InputSelectProps,
  ref: any
) => {
  const [isFocused, setIsFocused] = useState(false)

  const getPrefix = useCallback(() => {
    return (
      options?.find((el) => {
        return multiple ? value?.includes?.(el.value) : el.value === value
      })?.prefix || prefix
    )
  }, [options, value])
  const [currentPrefix, setCurrentPrefix] = useState(getPrefix())

  const changeValue = (v: any) => {
    let newValue: any = value ? [...value] : []
    if (multiple) {
      if (newValue?.includes(v)) {
        newValue = newValue.filter((el: any) => el !== v)
      } else {
        newValue.push(v)
      }
    } else {
      if (v === value) {
        newValue = ''
      } else {
        newValue = v
      }
    }
    const currentOpt = options?.find((el) => el.value === v)
    if (currentOpt?.prefix) {
      setCurrentPrefix(currentOpt?.prefix)
    }
    onChange(newValue)
  }
  return (
    <Container hasError={hasError}>
      <Label isFocused={isFocused || !!value?.length} hasPrefix={prefix}>
          {label} {required ? <Asterisk>*</Asterisk> : null}
      </Label>
      <FakeInput
        ref={ref}
        isFocused={isFocused}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className="input-bg"
        trigger="click"
        hasPrefix={!!prefix}
        isVisible={isFocused}
        popContent={
          <Menu>
            {options?.map((option, index) => {
              const isSelected =
                option.value === value ||
                (value?.length > 0 && value?.includes(option.value))
              return (
                <Menu.Item
                  key={index + option.value}
                  onClick={() => {
                    if (!multiple) {
                      setIsFocused(false)
                    }
                    changeValue(option.value)
                  }}
                >
                  {option?.prefix && (
                      <MenuItemPrefix>{option.prefix}</MenuItemPrefix>
                  )}
                  <MenuItemLabel>{option.label}</MenuItemLabel>
                  {isSelected && <Icon icon="check" />}
                </Menu.Item>
              )
            })}
          </Menu>
        }
      >
        {!!prefix && <Prefix>{currentPrefix}</Prefix>}
        <Suffix>
          <Icon icon="angle-small-down" />
        </Suffix>
        <InputSelectValue
          changeValue={changeValue}
          value={value}
          options={options}
          multiple={multiple}
        />
      </FakeInput>
    </Container>
  )
}

export default forwardRef(InputSelect)
