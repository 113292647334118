import React, { useState, forwardRef } from 'react'
import styled from 'styled-components'

const Container = styled.div<{ isFocused?: boolean; hasError?: boolean }>`
  background-color: ${({ theme, hasError }) => theme.background10};
  border-radius: 10px;
  position: relative;
`
const Input = styled.textarea<{ hasPrefix?: boolean }>`
  background-color: transparent !important;
  min-height: 100px;
  resize: none;
  padding: 25px 16px 8px ${({ hasPrefix }) => (hasPrefix ? '60px' : '16px')};
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  font-size: 14px;
  color: ${({ theme }) => theme.textPrimary};
  border: none;
  position: relative;
  z-index: 2;
  outline: rgba(0, 0, 0, 0);
`
const Label = styled.label<{
  isFocused?: boolean
  hasPrefix?: boolean
  hasError?: boolean
}>`
  position: absolute;
  padding: 0 16px 0 ${({ hasPrefix }) => (hasPrefix ? '60px' : '16px')};
  height: 25px;
  transition: all 0.4s cubic-bezier(0.54, 0.1, 0, 0.99);
  top: ${({ isFocused }) => (isFocused ? '8px' : '20px')};
  font-size: ${({ isFocused }) => (isFocused ? '13px' : '17px')};
  color: ${({ theme, hasError }) =>
    hasError ? theme.error : theme.textPlaceholder};
`

const Prefix = styled.div<{ hasError?: boolean }>`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  color: ${({ theme, hasError }) =>
    hasError ? theme.error : theme.textPrimary};
`

const Asterisk = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.error};
`
export interface InputTextAreaConfig {
  label: any
  prefix?: any
}
interface InputTextAreaProps extends InputTextAreaConfig {
  name: string
  value?: any
  onChange: (value?: any) => any
  hasError?: boolean
  required?: boolean
  className?: string
}
const InputTextArea = (
  {
    name,
    label,
    value,
    onChange,
    prefix,
    hasError,
    required,
    className,
  }: InputTextAreaProps,
  ref: any
) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Container
      isFocused={isFocused}
      hasError={hasError}
      className={`input-bg ${className}`}
    >
      {!!prefix && <Prefix hasError={hasError}>{prefix}</Prefix>}
      <Label
        htmlFor={name}
        isFocused={isFocused || value}
        hasPrefix={prefix}
        hasError={hasError}
      >
        {label} {required ? <Asterisk>*</Asterisk> : null}
      </Label>
      <Input
        ref={ref}
        hasPrefix={prefix}
        value={value}
        onChange={(e) => onChange(e?.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </Container>
  )
}

export default forwardRef(InputTextArea)
