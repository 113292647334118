import { deleteDoc, doc, getDoc, getFirestore } from 'firebase/firestore'
import { useContext } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import AuthContext from '../../contexts/AuthContext'

const UsersServices = {
  useFetchUser: () => {
    const authCtx = useContext(AuthContext)
    const userId = authCtx?.user?.uid as string
    return useQuery(
      `user-${userId}`,
      async () => {
        try {
          const docRef = doc(getFirestore(), 'users', userId)
          const res = await getDoc(docRef)

          const data = res.data()
          if (data && !data?.socialAccounts) {
            data.socialAccounts = []
          }

          return { id: res.id, ...data } as any
        } catch (err) {
          console.log(err)
        }
      },
      {
        refetchOnWindowFocus: false,
        enabled: !!userId,
      }
    )
  },
  useRemoveSocialAccount: () => {
    const queryClient = useQueryClient()
    const authCtx = useContext(AuthContext)
    const userId = authCtx?.user?.uid as string
    return (username: string) =>
      queryClient.setQueryData(`user-${userId}`, (oldData: any) => {
        if (Array.isArray(oldData)) {
          return oldData?.filter((data: any) => data?.id !== username)
        }
        return oldData
      })
  },
  useDeleteSocialAccount: () => {
    return useMutation(async ({ id }: any) => {
      const ref = doc(getFirestore(), 'sessions', id)
      return deleteDoc(ref)
    })
  },
}

export default UsersServices
