import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import 'react-toastify/dist/ReactToastify.css'
import Toast from './components/common/Toast/Toast'
import {ThemeProvider} from './contexts/ThemeContext'
import {QueryClient, QueryClientProvider} from 'react-query'
import AppRouter from './views/AppRouter'
import './i18n'
import { AuthProvider } from './contexts/AuthContext'

import './assets/uicons-bold-rounded/css/uicons-bold-rounded.css'
import './assets/uicons-bold-rounded/css/uicons-brands.css'

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Toast />
        <AppRouter />
        <div id="modal-root" />
      </ThemeProvider>
    </QueryClientProvider>
  </AuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
