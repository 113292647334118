import styled from 'styled-components'

const Row = styled.div<{
  flex?: number | string
  alignItems?: 'center' | 'flex-start' | 'flex-end'
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
  margin?: string
  maxWidth?: string
  gap?: string
  wrap?: any
}>`
  display: flex;
  flex-direction: row;
  max-width: ${({ maxWidth }) => maxWidth};
  flex: ${({ flex }) => flex || 'unset'};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => gap};
  justify-content: ${({ justifyContent }) => justifyContent};
  margin: ${({ margin }) => margin};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'no-wrap')};
`
export default Row
