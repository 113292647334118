import React, { useState, forwardRef, useCallback } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import Icon from '../../Icon/Icon'

const Container = styled.div<{ isFocused?: boolean; hasError?: boolean }>`
  background-color: ${({ theme, hasError }) => theme.background10};
  border-radius: 10px;
  position: relative;
  color: ${({ theme }) => theme.textPrimary};
  height: 45px;
  display: flex;
  flex-direction: row;
  padding: 10px 18px;
  font-size: 14px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const Zone = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
type LabelProps = {
  isFocused?: boolean
  hasPrefix?: boolean
  hasError?: boolean
}
const Label = styled.label<LabelProps>`
  transition: all 0.4s cubic-bezier(0.54, 0.1, 0, 0.99);
  color: ${({ theme, hasError }) => theme.textPrimary};
`

const Prefix = styled.div<{ hasError?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  color: ${({ theme }) => theme.textPrimary};
`
const Suffix = styled.div<{ hasError?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  color: ${({ theme }) => theme.textPrimary};
`

const Asterisk = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.error};
`
export interface InputFileConfig {
  label: any
  prefix?: any
  suffix?: any
  isPassword?: boolean
}

export const Text = styled.div`
  height: 60;
`
interface InputFileProps extends InputFileConfig {
  name: string
  value?: any
  onChange?: (value?: any) => any
  onFileChange?: (value?: any) => any
  hasError?: boolean
  required?: boolean
  className?: string
}
const InputFile = (
  {
    name,
    label,
    value,
    onChange,
    onFileChange,
    prefix,
    suffix,
    hasError,
    required,
    className,
  }: InputFileProps,
  ref: any
) => {
  const [visible, setVisible] = useState(false)
  const [files, setFiles] = useState(null as any)
  const [isFocused, setIsFocused] = useState(false)
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      !!onchange && onChange?.(URL.createObjectURL(acceptedFiles[0]))
      !!onFileChange && onFileChange?.(acceptedFiles)
      setFiles(acceptedFiles)
    },
    [onChange]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  return (
    <Container
      isFocused={isFocused}
      hasError={hasError}
      className={`input-bg ${className}`}
      {...getRootProps()}
    >
      {!!prefix && <Prefix hasError={hasError}>{prefix}</Prefix>}
      <Label
        htmlFor={name}
        isFocused={isFocused || files?.[0]?.path}
        hasPrefix={prefix}
        hasError={hasError}
      >
        {label} {required ? <Asterisk>*</Asterisk> : null}
      </Label>
      {/* <Input ref={ref} hasPrefix={prefix}>
        {files?.[0]?.path}
      </Input> */}
      <Zone {...getInputProps()} />
      {suffix && <Suffix hasError={hasError}>{suffix}</Suffix>}
    </Container>
  )
}

export default forwardRef(InputFile)
