import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div<{
  size?: number
  src?: string
  isClickable?: boolean
}>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  background-color: ${({ theme }) => theme.background20};
  background-size: cover;
  background-position: center;
  background-image: url(${({ src }) => src});
  border-radius: 100%;
  object-fit: cover;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : '')};
  &:hover {
    box-shadow: ${({ isClickable, theme }) =>
      isClickable ? theme.boxShadow : ''};
  }
`

const Bubble = styled.div`
  position: absolute;
  right: 5%;
  bottom: 2%;
  padding: 5px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 10px;
  &:hover {
    transform: scale(1.1);
  }
`
interface AvatarProps {
  src?: string
  size?: number
  onClick?: () => any
  className?: string
  children?: any
}

const Avatar = ({
  src,
  onClick,
  size = 50,
  className,
  children,
}: AvatarProps) => {
  return (
    <Container
      className={className}
      onClick={onClick}
      src={src}
      isClickable={!!onClick}
      size={size}
    >
      {children}
    </Container>
  )
}

Avatar.Bubble = Bubble
export default Avatar
