import React, { forwardRef, useCallback, useState } from 'react'
import styled from 'styled-components'
import Icon from '../../Icon/Icon'
import Popper from '../../Popper/Popper'

export const Prefix = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`
export const Suffix = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`
export const Label = styled.p<{ isFocused?: boolean; hasPrefix?: boolean }>`
  position: absolute;
  margin: 0;
  padding: 0 60px 0 ${({ hasPrefix }) => (hasPrefix ? '60px' : '16px')};
  height: 25px;
  transition: all 0.4s cubic-bezier(0.54, 0.1, 0, 0.99);
  top: ${({ isFocused }) => (isFocused ? '8px' : '50%')};
  transform: translateY(${({ isFocused }) => (isFocused ? '0' : '-50%')});
  font-size: ${({ isFocused }) => (isFocused ? '13px' : '17px')};
  color: ${({ theme }) => theme.textPlaceholder};
`

export const Container = styled.div<{
  isFocused?: boolean
  hasError?: boolean
}>`
  background-color: ${({ theme }) => theme.background10};
  border-radius: ${({ isFocused }) => (isFocused ? '10px 10px 0 0' : '10px')};
  position: relative;
  cursor: pointer;
  min-width: 200px;
  box-shadow: ${({ hasError, theme }) =>
    hasError ? ` ${theme.error} 0px 0px 0px 3px` : ''};
  ${Suffix} *, ${Prefix} *, ${Label} {
    color: ${({ theme, hasError }) => (hasError ? theme.error : '')}!important;
  }
`
export const FakeInput = styled(Popper)<{
  hasPrefix?: boolean
  isFocused?: boolean
}>`
  background-color: transparent !important;
  height: 60px;
  width: 100%;
  padding: 27px 60px 8px ${({ hasPrefix }) => (hasPrefix ? '60px' : '16px')};
  border-radius: ${({ isFocused }) => (isFocused ? '10px 10px 0 0' : '10px')};
  box-sizing: border-box;
  font-size: 14px;
  color: ${({ theme }) => theme.textPrimary};
  border: none;
  position: relative;
  z-index: 1;
  outline: rgba(0, 0, 0, 0);
`

export const Menu = styled.div`
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background};
  min-width: 200px;
  z-index: 2000;
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 5px;
  z-index: 10000;
`
export const MenuItem = styled.div`
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, color = 'background' }) =>
      (theme as any)?.[color + '10']};
  }
`
export const MenuItemLabel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.textPrimary};
`

export const MenuItemPrefix = styled.div`
  padding-right: 10px;
  min-width: 15px;
`
export const ValueTag = styled.div`
  padding: 5px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.background20};
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  &:hover {
    background-color: ${({ theme }) => theme.background30};
  }
  & + & {
    margin-left: 5px;
  }
`

export const Asterisk = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.error};
`
