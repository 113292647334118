import React from 'react'
import styled from 'styled-components'
import Col from '../common/Col/Col'
import Row from '../common/Row/Row'
import Text, { Title5, Title4, Title2 } from '../common/Text/Text'

export const Container = styled.div`
  margin-top: 50px;
  padding-top: 20px;
  background-color: ${({ theme }) => theme.background10};
`
export const Content = styled.div`
  margin: 0 auto;
  width: 1100px;
  max-width: 90%;
  padding: 1px 50px 50px 50px;
  box-sizing: border-box;
`

export const ContentLanguages = styled.div`
  margin: 0 auto;
  width: 1100px;
  max-width: 90%;
  padding: 25px 50px;
  box-sizing: border-box;
`
export const ContainerLanguages = styled.div`
  border-top: 1px solid ${({ theme }) => theme.borderColor};
`

export const Top = styled(Row)`
  margin: 50px auto;
  width: 1100px;
  max-width: 90%;
`

export const TextFooter = styled(Text)`
  margin: 3px 0;
`

export const TitleFooter1 = styled(Title4)`
  color: ${({ theme }) => theme.textPrimary};
`

export const TitleFooter = styled(Title4)`
  color: ${({ theme }) => theme.textPrimary};
`

export const TitleFooter5 = styled(Title5)`
  color: ${({ theme }) => theme.textSecondary};
`

export const LanguageFooter = styled(Text)`
  margin-right: 20px;
`