import React, { useState, forwardRef } from 'react'
import styled from 'styled-components'
import Icon from '../../Icon/Icon'
import {
  Container,
  Prefix,
  Label,
  Asterisk,
  Input,
  Suffix,
  SeeIcon,
} from './InputText.style'

export interface InputTextConfig {
  label: any
  prefix?: any
  isPassword?: boolean
}
interface InputTextProps extends InputTextConfig {
  name: string
  value?: any
  onChange: (value?: any) => any
  hasError?: boolean
  required?: boolean
  className?: string
}
const InputText = (
  {
    name,
    label,
    value,
    onChange,
    prefix,
    isPassword,
    hasError,
    required,
    className,
  }: InputTextProps,
  ref: any
) => {
  const [visible, setVisible] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Container
      isFocused={isFocused}
      hasError={hasError}
      className={`input-bg ${className}`}
    >
      {!!prefix && <Prefix hasError={hasError}>{prefix}</Prefix>}
      <Label
        htmlFor={name}
        isFocused={isFocused || value}
        hasPrefix={prefix}
        hasError={hasError}
      >
        {label} {required ? <Asterisk>*</Asterisk> : null}
      </Label>
      <Input
        ref={ref}
        hasPrefix={prefix}
        type={isPassword && !visible ? 'password' : 'text'}
        value={value}
        hasSuffix={isPassword}
        onChange={(e) => onChange(e?.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {isPassword && (
        <Suffix hasError={hasError} onClick={() => setVisible(!visible)}>
          <SeeIcon icon={visible ? 'eye-crossed' : 'eye'} />
        </Suffix>
      )}
    </Container>
  )
}

export default forwardRef(InputText)
