import styled from 'styled-components'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 20px 0;
  border-left: 3px dashed ${({ theme }) => theme.borderColor};
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 50%;
  padding: 0;
`

export const Separator = styled.div`
  min-width: 10px;
`

export const RemoveIcon = styled(Icon)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.error10};
  color: ${({ theme }) => theme.error};
  transition: all 0.2s cubic-bezier(0.54, 0.1, 0, 0.99);
  &:hover {
    transform: scale(1.1);
  }
`
export const AddButton = styled(Button)`
  background-color: ${({ theme }) => theme.info10};
  * {
    color: ${({ theme }) => theme.info};
  }
`
