import React from 'react'
import styled, { keyframes } from 'styled-components'
import { BrandsIcons, IconBrandsTypes, Icons, IconTypes } from './types'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const CustomIcon = styled.i<{ spin?: boolean, color?: string, size?: string}>`
  line-height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${({ spin }) => (spin ? rotate : null)} 2s linear infinite;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : '')};
    font-size: ${({ size }) => (size || '15px')};
  color: ${({ color, theme }) => (color || theme.textPrimary)};
`
interface IconType {
  icon: Icons
  type?: IconTypes
}
interface IconBrandsType {
  icon: BrandsIcons
  type?: IconBrandsTypes
}
interface IconDefinition {
  className?: any
  spin?: boolean
  onClick?: any
  color?: string
  size?: string
  onMouseEnter?: any
  onMouseLeave?: any
}
type IconProps = IconDefinition & (IconType | IconBrandsType)
const Icon = ({ icon, type = 'br', className, spin, ...props }: IconProps) => {
  return (
    <CustomIcon
      {...props}
      spin={spin}
      className={`fi fi-${type}-${icon} ${className}`}
    />
  )
}

export default Icon
