import React from 'react'
import {SessionStatus} from '../../hooks/useSession'
import Loader from '../common/Loader/Loader'
import Preview from '../Preview/Preview'
import StartLive from '../StartLive/StartLive'

interface SessionProps {
    children: any
    session: any
    checkConnection: any
    status?: SessionStatus
}

const Session = ({
                     children,
                     session,
                     checkConnection,
                     status
                 }: SessionProps) => {
    return (
        <Loader
            isLoading={session?.isLoading || checkConnection?.isLoading}
            withPreview={!session?.isLoading}
        >
            <Preview>{children}</Preview>
            {!checkConnection?.data && !checkConnection?.isLoading && (
                <StartLive
                    activityType={session.data.activityId}
                    onStart={() => checkConnection.refetch()}
                    isLoading={checkConnection?.isFetching}
                />
            )}
        </Loader>
    )
}

export default Session
