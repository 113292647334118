import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  flex-wrap: wrap;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 0;
`

export const Separator = styled.div`
  min-width: 10px;
`
