import { useQuery } from 'react-query'
import axios from 'axios'
import { useContext } from 'react'
import AuthContext from '../../contexts/AuthContext'

const URL = 'https://assets.licogames.com'

const GiftServices = {
  useFetchGifts: () => {
    const authCtx = useContext(AuthContext)
    return useQuery(
      'gifts',
      async () => {
        try {
          const newGifts = [] as any
          const getUrl = URL + '/gifts'
          const gifts: any = await axios.get(getUrl)
          for (const gift of gifts.data) {
            const data = {
              url: gift.image.url_list[0],
              price: gift.diamond_count,
              id: gift.id?.toString(),
              name: gift.name,
              description: gift.describe,
            }
            newGifts.push(data)
          }
          console.log('NEW GIFTS', newGifts)
          return newGifts
        } catch (err) {
          console.log(err)
        }
      },
      {
        refetchOnWindowFocus: false,
      }
    )
  },
}
export default GiftServices
