import React from 'react'
import styled from 'styled-components'
import { Title2 } from '../../../../components/common/Text/Text'

export const VotePart = styled.div<{ background?: string; src?: string }>`
  display: flex;
  flex: 1;
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: #161616;
  background: ${({ src }) => `url(${src})`};
  background-position: center;
  background-size: cover;
  position: relative;
`
export const VoteParts = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
  background-color:#FFFFFF;
`
export const FlashSeparator = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(50%);
  z-index: 10;
  height: 100%;
`

export const InformationTitle = styled(Title2)`
  margin: 0;
  font-weight: 600;
  line-height: 30px;
  font-size: 21px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
`
export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`
export const Informations = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  box-sizing: border-box;
`
export const InformationIcon = styled.img`
  height: 25px;
`
export const InformationItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #262626;
  border-radius: 10px;
  color: #FFFFFF;
  margin-bottom: 10px;
  padding: 10px 20px;
`
export const InformationPrefix = styled.div`
  margin-right: 10px;
`
export const InformationSuffix = styled.div`
  margin-left: 10px;
`

export const InformationImg = styled.img`
  height: 25px;
`
export const InformationIconItemNumber = styled.div`
  height: 30px;
  width: 30px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #4d4d4d;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  color: #FFFFFF;
  justify-content: center;
  align-items: center;
`
export const Shadow = styled.div`
  background: linear-gradient(
    0deg,
    rgba(22, 22, 22, 1) 0%,
    rgba(22, 22, 22, 0.75) 50%,
    rgba(22, 22, 22, 0) 100%
  );
  /* backdrop-filter: blur(3px); */
  z-index: 12;
  padding: 10px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  min-height: 50px;
`
