import styled, { css } from 'styled-components'

export const PreviewSizer = styled.div`
  padding-top: 178%;
`
export const Container = styled.div<{ fullScreen?: boolean }>`
  width: 350px;
  min-width: 350px;
  max-width: 100%;
  border-radius: 20px;
  margin: auto;

  background-color: '#161616';
  position: sticky;
  top: 100px;
  overflow: hidden;
  ${({ fullScreen }) =>
    fullScreen
      ? css`
          z-index: 10000;
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 0%; ;
        `
      : ''}
`
export const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #161616;
`
