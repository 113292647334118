import styled, { css } from 'styled-components'
import { mediaQueries } from '../../../utils'

interface GridProps {
  columns?: number | string
  space?: number | string
  responsive?: {
    mobileS?: number | string
    mobileM?: number | string
    mobileL?: number | string
    tablet?: number | string
    laptop?: number | string
    laptopL?: number | string
    desktop?: number | string
    desktopL?: number | string
  }
}
const renderGridTempleColumns = (value: any, mediaQuery: any) => {
  if (!value) return ''
  return `
    @media ${mediaQuery} {
      grid-template-columns: ${`repeat( ${value}, 1fr)`};
    }
  `
}
const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat( ${columns}, 1fr)`};
  grid-gap: ${({space}) => (space ?? '10') + 'px'};
  grid-auto-rows: minmax(20px, auto);
  ${({ responsive }) => {
    return (
      renderGridTempleColumns(responsive?.desktopL, mediaQueries.desktopL) +
      renderGridTempleColumns(responsive?.desktop, mediaQueries.desktop) +
      renderGridTempleColumns(responsive?.laptopL, mediaQueries.laptopL) +
      renderGridTempleColumns(responsive?.laptop, mediaQueries.laptop) +
      renderGridTempleColumns(responsive?.tablet, mediaQueries.tablet) +
      renderGridTempleColumns(responsive?.mobileL, mediaQueries.mobileL) +
      renderGridTempleColumns(responsive?.mobileM, mediaQueries.mobileM) +
      renderGridTempleColumns(responsive?.mobileS, mediaQueries.mobileS)
    )
  }}
`

export default Grid
