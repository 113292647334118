import React, { useState } from 'react'
import {
  DefaultTheme,
  ThemeProvider as StyledComponentThemeProvider,
} from 'styled-components'
import { tint, shade, rgba } from 'polished'

interface CreateThemeProps {
  primary: string
  secondary: string
  tertiary: string
  success: string
  error: string
  warning: string
  info: string
  gray: string
  isDark: boolean
}

const createTheme = ({
  primary,
  secondary,
  tertiary,
  success,
  error,
  warning,
  info,
  gray,
  isDark = false,
}: CreateThemeProps) => {
  const palette = {
    // PRIMARY COLOR
    primary,
    primary10: tint(0.8, primary),
    primary20: tint(0.6, primary),
    primary30: tint(0.4, primary),
    primary40: tint(0.2, primary),
    primary50: primary,
    primary60: shade(0.2, primary),
    primary70: shade(0.4, primary),
    primary80: shade(0.6, primary),
    primary90: shade(0.8, primary),

    // SECONDARY COLOR
    secondary,
    secondary10: tint(0.8, secondary),
    secondary20: tint(0.6, secondary),
    secondary30: tint(0.4, secondary),
    secondary40: tint(0.2, secondary),
    secondary50: secondary,
    secondary60: shade(0.2, secondary),
    secondary70: shade(0.4, secondary),
    secondary80: shade(0.6, secondary),
    secondary90: shade(0.8, secondary),

    // TERTIARY COLOR
    tertiary,
    tertiary10: tint(0.8, tertiary),
    tertiary20: tint(0.6, tertiary),
    tertiary30: tint(0.4, tertiary),
    tertiary40: tint(0.2, tertiary),
    tertiary50: tertiary,
    tertiary60: shade(0.2, tertiary),
    tertiary70: shade(0.4, tertiary),
    tertiary80: shade(0.6, tertiary),
    tertiary90: shade(0.8, tertiary),

    // SUCCESS COLOR
    success,
    success10: tint(0.8, success),
    success20: tint(0.6, success),
    success30: tint(0.4, success),
    success40: tint(0.2, success),
    success50: success,
    success60: shade(0.2, success),
    success70: shade(0.4, success),
    success80: shade(0.6, success),
    success90: shade(0.8, success),

    // DANGER COLOR
    error,
    error10: tint(0.8, error),
    error20: tint(0.6, error),
    error30: tint(0.4, error),
    error40: tint(0.2, error),
    error50: error,
    error60: shade(0.2, error),
    error70: shade(0.4, error),
    error80: shade(0.6, error),
    error90: shade(0.8, error),

    // WARNING COLOR
    warning,
    warning10: tint(0.8, warning),
    warning20: tint(0.6, warning),
    warning30: tint(0.4, warning),
    warning40: tint(0.2, warning),
    warning50: warning,
    warning60: shade(0.2, warning),
    warning70: shade(0.4, warning),
    warning80: shade(0.6, warning),
    warning90: shade(0.8, warning),

    // INFO COLOR
    info,
    info10: tint(0.8, info),
    info20: tint(0.6, info),
    info30: tint(0.4, info),
    info40: tint(0.2, info),
    info50: info,
    info60: shade(0.2, info),
    info70: shade(0.4, info),
    info80: shade(0.6, info),
    info90: shade(0.8, info),
    // GRAY COLOR
    gray,
    gray0: '#FFFFFF',
    gray10: tint(0.8, gray),
    gray20: tint(0.6, gray),
    gray30: tint(0.4, gray),
    gray40: tint(0.2, gray),
    gray50: gray,
    gray60: shade(0.2, gray),
    gray70: shade(0.4, gray),
    gray80: shade(0.6, gray),
    gray90: shade(0.8, gray),
    grayA0: shade(0.9, gray),
  }

  // BORDER

  const borderColor = '#2e2e2e'
  const boxShadow = isDark ? '' : 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
  return {
    ...palette,
    background: '#FFFFFF',
    background10: '#edeef0',
    background20: '#e3e3e3',
    background30: '#e0e0e0',
    background40: '#383838',
    textPrimary: '#161616',
    textSecondary: '#706668',
    textTertiary: '#aaa2a4',
    textPlaceholder: '#aaa2a4',
    textDisabled: '#aaa2a4',
    borderColor: 'rgba(0,0,0,0.1)',
    boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.2)',
    isDark: isDark ?? false,
    borderRadius: 10,
  } as DefaultTheme
}

interface ThemeContextInterface {
  toggleDarkMode: () => void
  theme: DefaultTheme
}

const ThemeContext = React.createContext<ThemeContextInterface>({} as any)

interface ThemeProviderProps {
  children: any
}
export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [palette] = useState({
    primary: '#ff2f5c',
    secondary: '#F5E000',
    tertiary: '#F02E58',
    success: '#00e094',
    error: '#F02E58',
    warning: 'rgb(245, 139, 0)',
    info: '#5386E4',
    gray: '#a4abb7',
  })
  const [theme, setTheme] = useState(createTheme({ ...palette, isDark: false }))

  const toggleDarkMode = () => {
    setTheme(createTheme({ ...palette, isDark: !theme.isDark }))
  }
  return (
    <StyledComponentThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ toggleDarkMode, theme }}>
        {children}
      </ThemeContext.Provider>
    </StyledComponentThemeProvider>
  )
}
export default ThemeContext
