import React, { forwardRef, useCallback, useState } from 'react'
import ModalFileManager from '../../../../modals/ModalFileManager'
import Icon from '../../Icon/Icon'
import { useModal } from '../../Modal/Modal'
import {
  Container,
  Label,
  Asterisk,
  FakeInput,
  Prefix,
  Suffix,
  Value
} from './InputFilePicker.style'

export interface InputFilePickerConfig {
  prefix?: any
  label: any
  required?: boolean
}

interface InputFilePickerProps extends InputFilePickerConfig {
  name: string
  value?: string
  onChange: (value?: any) => any
  hasError?: boolean
}
const InputFilePicker = (
  {
    name,
    label,
    value = '',
    onChange,
    prefix,
    required,
    hasError,
  }: InputFilePickerProps,
  ref: any
) => {
  const modalFileManager = useModal()
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Container hasError={hasError}>
      <Label isFocused={isFocused || !!value?.length} hasPrefix={prefix}>
        {label} {required ? <Asterisk>*</Asterisk> : null}
      </Label>
      <FakeInput
        ref={ref}
        type="button"
        isFocused={isFocused}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className="input-bg"
        hasPrefix={!!prefix}
        onClick={() => modalFileManager.open()}
      >
        {!!prefix && <Prefix>{prefix}</Prefix>}
        <Value hasPrefix={!!prefix}>{value}</Value>
        <Suffix>
          <Icon icon="angle-small-down" />
        </Suffix>
      </FakeInput>
      <ModalFileManager
        {...modalFileManager}
        value={value}
        onChange={(v) => onChange(v)}
      />
    </Container>
  )
}

export default forwardRef(InputFilePicker)
