import {
  addDoc,
  collection,
  getFirestore,
  query,
  where,
  onSnapshot,
  deleteDoc,
  doc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
} from 'firebase/firestore'
import { useContext, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import AuthContext from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

export interface SessionEditable<Type> {
  name: string
  data: Type
}

export interface Session extends SessionEditable<any> {
  authorId: string
  userId: string
  activityId: string
}

const SessionServices = {
  useFetchPersonnalSessions: () => {
    const authCtx = useContext(AuthContext)
    const [sessions, setSessions] = useState(null as any)

    return useQuery(
      'personnalSessions',
      async () => {
        try {
          const data: any[] = []
          const q = query(
            collection(getFirestore(), 'sessions'),
            where('authorId', '==', authCtx?.user?.uid)
          )
          const res = await getDocs(q)
          res?.forEach((doc) => data.push({ id: doc.id, ...doc.data() }))
          return data
        } catch (err) {
          console.log(err)
        }
      },
      {
        refetchOnWindowFocus: false,
        enabled: !!authCtx?.user?.uid,
      }
    )
  },
  useFetchSession: (sessionId: string, enabled = true) => {
    return useQuery(
      sessionId + '_session',
      async () => {
        try {
          const ref = doc(getFirestore(), 'sessions', sessionId)
          const res = await getDoc(ref)
          return { id: res.id, ...res.data() } as any as Session
        } catch (err) {
          console.log(err)
        }
      },
      {
        refetchOnWindowFocus: false,
        enabled,
      }
    )
  },
  useUpsertSession: (activityType: string) => {
    const authCtx = useContext(AuthContext)
    const authorId = authCtx?.user?.uid
    const navigate = useNavigate()
    return useMutation(
      async ({
        session,
        sessionId,
      }: {
        session: SessionEditable<any>
        sessionId?: string
      }) => {
        let id = sessionId

        if (sessionId) {
          const ref = doc(getFirestore(), 'sessions', sessionId)
          await updateDoc(ref, { ...session })
        } else {
          const ref = collection(getFirestore(), 'sessions')
          const response = await addDoc(ref, {
            ...session,
            authorId,
            activityType,
          })
          id = response.id
        }
        navigate(`/games/${activityType}/session/${id}`)
      }
    )
  },

  useRemovePersonnalSessionFromCache: () => {
    const queryClient = useQueryClient()
    return (sessionId: string) =>
      queryClient.setQueryData('personnalSessions', (oldData: any) => {
        if (Array.isArray(oldData)) {
          return oldData?.filter((data: any) => data?.id !== sessionId)
        }
        return oldData
      })
  },
  useDeleteSession: () => {
    return useMutation(async ({ id }: any) => {
      const ref = doc(getFirestore(), 'sessions', id)
      return deleteDoc(ref)
    })
  },
}
export default SessionServices
