import React, { useCallback, useState } from 'react'
import { Backdrop, Container, LoadingIcon, LoadingState } from './Loader.style'

export const useLoader = () => {
  const [isLoading, setIsLoading] = useState(false)

  const stop = useCallback(() => {
    setIsLoading(false)
  }, [])

  const start = useCallback(() => {
    setIsLoading(true)
  }, [])

  return { isLoading, stop, start }
}

export interface LoaderProps {
  className?: string
  isLoading?: boolean
  isFullScreen?: boolean
  withPreview?: boolean
  children?: any
  loadingContent?: any
}

/**
 * @param className - The css class of the container (used by styled components to extend style)
 * @param isLoading - The loadingState
 * @param isFullScreen - If true the component will take all screen size
 * @param withPreview - If true the component will show the content behind the loading indicator
 * @param children - The content
 * @param loadingContent - The contentto show when loading
 * @returns
 */
const Loader = ({
  className,
  isLoading,
  isFullScreen,
  withPreview = false,
  children,
  loadingContent,
}: LoaderProps) => {
  return (
    <Container isFullScreen={isFullScreen} className={className}>
      {(withPreview || !isLoading) && children}
      {isLoading && (
        <>
          <Backdrop />
          <LoadingState>
            {loadingContent ?? <LoadingIcon icon="loading" spin />}
          </LoadingState>
        </>
      )}
    </Container>
  )
}

export default Loader
