import React from 'react'
import styled from 'styled-components'
import Row from '../Row/Row'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 350px;
`
export const Error = styled.p`
  margin: 0;
  text-align: right;
  justify-content: flex-end;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.error};
  margin: 3px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
`

export const Container = styled.div<{ maxWidth?: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
  margin: auto;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
`

const StepIndicator = styled.div<{
  current: number
  maxIndex: number
  index: number
}>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: ${({ current, theme, maxIndex, index }) => {
    if (current >= index) {
      return theme?.primary
    } else if (maxIndex >= index) {
      return theme.textTertiary
    }
    return theme.background30
  }};
  color: ${({ current, theme, maxIndex, index }) => {
    if (current >= index) {
      return theme.background
    } else if (maxIndex >= index) {
      return theme.background
    }
    return '#FFFFFF'
  }};
  cursor: pointer;
`

const StepSeparator = styled.div<{
  current: number
  maxIndex: number
  index: number
}>`
  height: 5px;
  display: flex;
  flex: 1;
  font-weight: 600;
  max-width: 30px;
  background-color: ${({ current, theme, maxIndex, index }) => {
    if (current >= index) {
      return theme?.primary
    } else if (maxIndex >= index) {
      return theme.textTertiary
    }
    return theme.background30
  }};
`

interface StepIndicator {
  length: number
  current: number
  maxIndex: number
  onClick: (value: number) => void
}
export const StepsIndicator = ({
  length,
  current,
  onClick,
  maxIndex,
}: StepIndicator) => {
  return (
    <Row
      flex="1"
      alignItems="center"
      justifyContent="center"
      margin="0 0 20px 0"
    >
      {Array(length)
        .fill(0)
        ?.map((el, index) => (
          <>
            <StepIndicator
              key={index}
              current={current}
              index={index}
              maxIndex={maxIndex}
              onClick={() => (index <= maxIndex ? onClick(index) : null)}
            >
              {index + 1}
            </StepIndicator>
            {index !== length - 1 && (
              <StepSeparator
                current={current}
                index={index + 1}
                maxIndex={maxIndex}
              />
            )}
          </>
        ))}
    </Row>
  )
}
