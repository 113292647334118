import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import socketIOClient, { Socket } from 'socket.io-client'
import SessionServices from '../services/data/session'

const ENDPOINT = 'https://services.licogames.com'

const checkConnection = async (
  sessionId: string,
  username: string,
  options: any
) => {
  const socket = socketIOClient(ENDPOINT)
  return new Promise((resolve, reject) => {
    socket.on('connect', () => {
      if (username && sessionId) {
        socket.emit('setUniqueId', { sessionId, uniqueId: username }, options)
        socket.once('tiktokConnected', resolve)
        socket.once('tiktokDisconnected', reject)
        socket.once('disconnected', reject)
      } else {
        reject('Invalid params')
      }
    })
  })
}
const useCheckConnection = (options: any, onSuccess: () => any) => {
  const { sessionId, username } = useParams() as any
  return useQuery(
    ['check-connection', sessionId],
    () => checkConnection(sessionId, username, options),
    {
      enabled: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess,
      onError: () => {
        toast.error('You must start your live on tiktok before contine')
      },
      staleTime: 1500,
    }
  )
}
export enum SessionStatus {
  ERROR = 'error',
  INITIALIZING = 'initializing',
  INITIALIZED = 'initialized',
  DISCONNECTED = 'disconnected',
  CONNECTED = 'connected',
  TIKTOK_DISCONNECTED = 'tiktokDisconnected',
  STREAM_END = 'streamEnd',
}

interface SessionListener {
  onInit?: () => void
  onConnect?: (data: any) => void
  onDisconnect?: () => void
  onStreamEnd?: () => void
  onTiktokDisconnected?: () => void
  onRoomUser?: (data: any) => void
  onLike?: (data: any) => void
  onMember?: (data: any) => void
  onChat?: (data: any) => void
  onGift?: (data: any) => void
  onSocial?: (data: any) => void
}
const useSession = (listen: SessionListener) => {
  const { sessionId, username } = useParams() as any
  const [options] = useState({ enableExtendedGiftInfo: true })
  const [sessionStatus, setSessionStatus] = useState<
    SessionStatus | undefined
  >()

  const connectSocket = (socket: Socket) => {
    return new Promise((resolve, reject) => {
      if (username && sessionId) {
        socket.emit('setUniqueId', { sessionId, uniqueId: username }, options)
        socket.once('tiktokConnected', resolve)
        socket.once('tiktokDisconnected', reject)

        setTimeout(() => {
          reject('Connection Timeout')
        }, 15000)
      } else {
        reject('Invalid params')
      }
    })
  }

  const initSocket = () => {
    const socket = socketIOClient(ENDPOINT)
    socket.on('connect', () => {
      setSessionStatus(SessionStatus.INITIALIZING)
      listen.onInit?.()
      connectSocket(socket)
        .then((state: any) => {
          setSessionStatus(SessionStatus.INITIALIZED)
          listen.onConnect?.(state)
          console.log(`Connected to roomId ${state.roomId}`)
        })
        .catch(() => {
          setSessionStatus(SessionStatus.ERROR)
          socket.close()
        })
    })

    if (listen.onDisconnect) {
      socket.on('disconnect', () => {
        setSessionStatus(SessionStatus.DISCONNECTED)
        listen.onDisconnect?.()
      })
    }

    if (listen.onStreamEnd) {
      socket.on('streamEnd', () => {
        setSessionStatus(SessionStatus.STREAM_END)
        listen.onStreamEnd?.()
      })
    }
    if (listen.onTiktokDisconnected) {
      socket.on('tiktokDisconnected', () => {
        setSessionStatus(SessionStatus.TIKTOK_DISCONNECTED)
        listen.onTiktokDisconnected?.()
      })
    }
    if (listen.onRoomUser) {
      socket.on('roomUser', (data: any) => {
        listen.onRoomUser?.(data)
      })
    }

    if (listen.onLike) {
      socket.on('like', (data: any) => {
        listen.onLike?.(data)
      })
    }
    if (listen.onMember) {
      socket.on('member', (data: any) => {
        listen.onMember?.(data)
      })
    }

    if (listen.onChat) {
      socket.on('chat', (data: any) => {
        listen.onChat?.(data)
      })
    }
    if (listen.onGift) {
      socket.on('gift', (data: any) => {
        listen.onGift?.(data)
      })
    }

    if (listen.onSocial) {
      socket.on('social', (data: any) => {
        listen.onSocial?.(data)
      })
    }
  }

  const checkConnection = useCheckConnection(options, () => initSocket())
  const session = SessionServices.useFetchSession(sessionId)

  return {
    checkConnection,
    session,
    data: session?.data,
    status: sessionStatus,
  }
}

export default useSession
