import React, { forwardRef } from 'react'
import ReactDOM from 'react-dom'
import { TriggerType, usePopperTooltip } from 'react-popper-tooltip'
import styled, { keyframes } from 'styled-components'

export const fadefromBottom = keyframes`
  from {
    max-height: 0px;
    overflow: hidden;
    margin-top: -10px;
    opacity: 0.5;
  }

  to {
    max-height: 1000px;
    margin-top: 0;
    opacity: 1;
    overflow: unset;
  }
`
const PopperContainer = styled.div`
  background-color: ${({ theme }) => theme.background10};
  box-shadow: ${({ theme }) => theme.boxShadow};
  animation-name: ${fadefromBottom};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.54, 0.1, 0, 0.99);
  border-radius: 15px;
  z-index: 9000000;
  border: 1px solid ${({ theme }) => theme.borderColor};
`
const Container = styled.div``
interface PopperProps {
  children: any
  popContent?: any
  trigger?: TriggerType | TriggerType[] | null
  className?: string
  onFocus?: () => void
  onBlur?: () => void
  closeOnOutsideClick?: boolean
  isVisible?: boolean
  placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end'
}
const Popper = (
  {
    children,
    popContent,
    placement = 'bottom-end',
    trigger,
    className,
    onFocus,
    onBlur,
    isVisible,
  }: PopperProps,
  ref: any
) => {
  const {
    getTooltipProps,
    getArrowProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger,
    placement,
    visible: trigger === 'click' ? isVisible : undefined,
    delayHide: 50,
    interactive: true,
    onVisibleChange: (v) => {
      v ? onFocus?.() : onBlur?.()
    },
  })

  return (
    <Container ref={setTriggerRef} className={className}>
      {children}
      {visible &&
        ReactDOM.createPortal(
          <PopperContainer ref={setTooltipRef} {...getTooltipProps()}>
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            {popContent}
          </PopperContainer>,
          document.getElementById('modal-root') as any
        )}
    </Container>
  )
}

export default forwardRef(Popper)
