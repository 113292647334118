import React, { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import Button from '../Button/Button'
import Text from '../Text/Text'
import FormItem from '../FormItem/FormItem'
import { Form, FormFooter, FormHeader } from './FormBuilder.style'
import { FormBuilderProps } from './FormBuilder.types'
import Loader from '../Loader/Loader'

const FormBuilder = ({
  submitLabel,
  fields,
  onSubmit,
  isLoading = false,
  config,
  trigger = 'submit',
  onChange,
  header,
  footer,
  resolver,
  ...rest
}: FormBuilderProps) => {
  const methods = useForm({ resolver, ...rest })

  useEffect(() => {
    const subscription = methods.watch((value) => {
      onChange?.(value)
    })
    return () => subscription.unsubscribe()
  }, [methods.watch()])
  return (
    <FormProvider {...methods}>
      <Form action="#" onSubmit={methods.handleSubmit(onSubmit)}>
        <Loader isLoading={isLoading} withPreview>
          {!!header && <FormHeader>{header}</FormHeader>}
          {fields?.map((field) => (
            <FormItem key={field.name} {...field} />
          ))}
        </Loader>
        <FormFooter>
          {footer ?? (
            <Button color="primary" type="submit" expand isLoading={isLoading}>
              {submitLabel ?? 'Submit'}
            </Button>
          )}
        </FormFooter>
      </Form>
    </FormProvider>
  )
}

export default FormBuilder
