import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router'
import SessionServices, { SessionEditable } from '../services/data/session'

function useConfigureSession<T>(initialValue: any, activityType: string) {
  const params = useParams() as any
  const id = params?.sessionId
  const session = SessionServices.useFetchSession(id || '', !!id)

  const upsert = SessionServices.useUpsertSession(activityType)
  const [defaultValue, setDefaultValue] = useState<
    SessionEditable<T> | undefined
  >(undefined)

  const [value, setValue] = useState<SessionEditable<T> | undefined>(
    initialValue
  )

  useEffect(() => {
    if (session?.data) {
      setDefaultValue(session?.data as SessionEditable<T>)
      setValue(session?.data as SessionEditable<T>)
    }
  }, [session?.data])

  return {
    data: value,
    isLoading: session?.isLoading || (!!id && !defaultValue),
    defaultValue,
    submit: {
      ...upsert,
      mutate: async () =>
        upsert.mutateAsync({
          session: value as SessionEditable<T>,
          sessionId: id,
        }),
    },
    onChange: (v: SessionEditable<T>) => setValue(v),
  }
}
export default useConfigureSession
