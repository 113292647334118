import React, { useState, forwardRef } from 'react'
import styled from 'styled-components'
import Icon from '../../Icon/Icon'

export const Container = styled.div<{
  isFocused?: boolean
  hasError?: boolean
}>`
  background-color: ${({ theme, hasError }) => theme.background10};
  border-radius: 10px;
  position: relative;
  width: 100%;
  box-shadow: ${({ hasError, theme }) =>
    hasError ? ` ${theme.error} 0px 0px 0px 3px` : ''};
`
export const Input = styled.input<{ hasPrefix?: boolean; hasSuffix?: boolean }>`
  background-color: transparent !important;
  height: 60px;
  padding: 20px ${({ hasSuffix }) => (hasSuffix ? '60px' : '16px')} 8px
    ${({ hasPrefix }) => (hasPrefix ? '60px' : '16px')};
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  font-size: 14px;
  color: ${({ theme }) => theme.textPrimary};
  border: none;
  position: relative;
  z-index: 2;
  outline: rgba(0, 0, 0, 0);
`
export const Label = styled.label<{
  isFocused?: boolean
  hasPrefix?: boolean
  hasError?: boolean
}>`
  position: absolute;
  padding: 0 16px 0 ${({ hasPrefix }) => (hasPrefix ? '60px' : '16px')};
  height: 25px;
  transition: all 0.4s cubic-bezier(0.54, 0.1, 0, 0.99);
  top: ${({ isFocused }) => (isFocused ? '8px' : '50%')};
  transform: translateY(${({ isFocused }) => (isFocused ? '0' : '-50%')});
  font-size: ${({ isFocused }) => (isFocused ? '13px' : '17px')};
  color: ${({ theme, hasError }) =>
    hasError ? theme.error : theme.textPlaceholder};
`

export const Prefix = styled.div<{ hasError?: boolean }>`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  color: ${({ theme, hasError }) =>
    hasError ? theme.error : theme.textPrimary};
`
export const Suffix = styled.div<{ hasError?: boolean }>`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 2;
  color: ${({ theme, hasError }) =>
    hasError ? theme.error : theme.textPrimary};
`

export const SeeIcon = styled(Icon)`
  padding: 10px;
  border-radius: 10px;
  &:hover {
    background-color: ${({ theme }) => theme.background20};
  }
`

export const Asterisk = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.error};
`
export interface InputTextConfig {
  label: any
  prefix?: any
  isPassword?: boolean
}
interface InputTextProps extends InputTextConfig {
  name: string
  value?: any
  onChange: (value?: any) => any
  hasError?: boolean
  required?: boolean
  className?: string
}
