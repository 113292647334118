import React, { Suspense } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import Loader from '../components/common/Loader/Loader'
import Contact from './Contact/Contact'
import About from './About/About'
import VoteSession from './Games/Vote/VoteSession'
import VoteSessionPreview from './Games/Vote/VoteSessionPreview'
import VoteConfiguration from './Games/Vote/VoteConfiguration'
import { GAMES } from '../utils/games'

const PixelsRevealConfiguration = React.lazy(
  () => import('./Games/PixelsReveal/PixelsRevealConfiguration')
)
const PixelsRevealSessionPreview = React.lazy(
  () => import('./Games/PixelsReveal/PixelsRevealSessionPreview')
)
const PixelsRevealSession = React.lazy(
  () => import('./Games/PixelsReveal/PixelsRevealSession')
)

const Games = React.lazy(() => import('./Games/Games'))
const Home = React.lazy(() => import('./Home/Home'))
const MySessions = React.lazy(() => import('./MySessions/MySessions'))
const Profil = React.lazy(() => import('./Profil/Profil'))
const Privacy = React.lazy(() => import('./Privacy/Privacy'))
const Terms = React.lazy(() => import('./Terms/Terms'))
const VersusSession = React.lazy(() => import('./Games/Versus/VersusSession'))
const VersusConfiguration = React.lazy(
  () => import('./Games/Versus/VersusConfiguration')
)
const GamesContainer = React.lazy(() => import('./Games/GamesContainer'))
const GamePresentation = React.lazy(
  () => import('../components/GamePresentation/GamePresentation')
)
const VersusSessionPreview = React.lazy(
  () => import('./Games/Versus/VersusSessionPreview')
)
const MyAccount = React.lazy(() => import('./MyAccount/MyAccount'))

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader isFullScreen isLoading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/games"
          element={
            <Suspense fallback={<Loader isFullScreen isLoading />}>
              <GamesContainer />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<Loader isFullScreen isLoading />}>
                <Games />
              </Suspense>
            }
          />
          {/***********************************************
           *                 PIXELS-REVEAL
           * **********************************************/}
          <Route
            path="PIXELS_REVEAL"
            element={
              <Suspense fallback={<Loader isFullScreen isLoading />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <GamePresentation id="PIXELS_REVEAL" />
                </Suspense>
              }
            />
            <Route
              path="configuration"
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <PixelsRevealConfiguration />
                </Suspense>
              }
            />
            <Route
              path="configuration/:sessionId"
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <PixelsRevealConfiguration />
                </Suspense>
              }
            />
            <Route
              path="session/:sessionId"
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <PixelsRevealSessionPreview />
                </Suspense>
              }
            />

            <Route
              path="session/:sessionId/:username"
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <PixelsRevealSession />
                </Suspense>
              }
            />
          </Route>

          {/***********************************************
           *                  VERSUS
           * **********************************************/}
          <Route
            path={GAMES.VERSUS}
            element={
              <Suspense fallback={<Loader isFullScreen isLoading />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <GamePresentation id={GAMES.VERSUS} />
                </Suspense>
              }
            />
            <Route
              path="configuration"
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <VersusConfiguration />
                </Suspense>
              }
            />
            <Route
              path="configuration/:sessionId"
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <VersusConfiguration />
                </Suspense>
              }
            />
            <Route
              path="session/:sessionId"
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <VersusSessionPreview />
                </Suspense>
              }
            />
            <Route
              path="session/:sessionId/:username"
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <VersusSession />
                </Suspense>
              }
            />
          </Route>


          {/***********************************************
           *                  VOTE
           * **********************************************/}
          <Route
            path="VOTE"
            element={
              <Suspense fallback={<Loader isFullScreen isLoading />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <GamePresentation id="VOTE" />
                </Suspense>
              }
            />
            <Route
              path="configuration"
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <VoteConfiguration />
                </Suspense>
              }
            />
            <Route
              path="configuration/:sessionId"
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <VoteConfiguration />
                </Suspense>
              }
            />
            <Route
              path="session/:sessionId"
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <VoteSessionPreview />
                </Suspense>
              }
            />
            <Route
              path="session/:sessionId/:username"
              element={
                <Suspense fallback={<Loader isFullScreen isLoading />}>
                  <VoteSession />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="/profil"
          element={
            <Suspense fallback={<Loader isFullScreen isLoading />}>
              <Profil />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<Loader isFullScreen isLoading />}>
                <MyAccount />
              </Suspense>
            }
          />
          <Route
            path="sessions"
            element={
              <Suspense fallback={<Loader isFullScreen isLoading />}>
                <MySessions />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/privacy"
          element={
            <Suspense fallback={<Loader isFullScreen isLoading />}>
              <Privacy />
            </Suspense>
          }
        />
        <Route
          path="/terms"
          element={
            <Suspense fallback={<Loader isFullScreen isLoading />}>
              <Terms />
            </Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <Suspense fallback={<Loader isFullScreen isLoading />}>
              <Contact />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<Loader isFullScreen isLoading />}>
              <About />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
