import { useMutation, useQuery } from 'react-query'
import axios from 'axios'
import { useContext } from 'react'
import AuthContext from '../../contexts/AuthContext'

const URL = 'https://assets.licogames.com'

const GalleryServices = {
  useFetchUserGallery: () => {
    const authCtx = useContext(AuthContext)
    return useQuery(
      'userGallery_' + authCtx.user?.uid,
      async () => {
        try {
          const newGallery = [] as any
          const getUrl = URL + '/files?directory=images/' + authCtx.user?.uid
          const files: any = await axios.get(getUrl)
          for (const file of files.data) {
            newGallery.push(file)
          }
          return newGallery
        } catch (err) {
          console.log(err)
        }
      },
      {
        refetchOnWindowFocus: false,
      }
    )
  },
  usePushToGallery: () => {
    const authCtx = useContext(AuthContext)
    return useMutation(async ({ files }: any) => {
      if (authCtx.user?.uid) {
        for (let i = 0; i < files.length; i++) {
          const newFile = files[i]
          const formData = new FormData()
          formData.append('file', newFile, newFile.name)
          await axios.post(
            URL + '/upload?directory=images/' + authCtx.user?.uid,
            formData
          )
        }
      } else {
        console.log('NOT CONNECTED')
      }
      return
    })
  },
}
export default GalleryServices
