import React from 'react'
import { Field } from '../common/FormBuilder/FormBuilder.types'
import FormStepBuilder from '../common/FormStepBuilder/FormStepBuilder'
import Loader from '../common/Loader/Loader'
import Preview from '../Preview/Preview'

interface ConfigurationProps {
  children: any
  steps: Array<Field[]>
  session: any
}

const Configuration = ({ children, steps, session }: ConfigurationProps) => {
  return (
    <Loader
      isLoading={session?.isLoading || session?.submit?.isLoading}
      withPreview={session?.submit?.isLoading}
      isFullScreen={session?.isLoading}
    >
      <FormStepBuilder
        steps={steps}
        maxWidth="550px"
        defaultValues={session?.defaultValue}
        onSubmit={() => session?.submit.mutate()}
        onChange={session?.onChange}
        preview={(v) => <Preview>{children(v)}</Preview>}
      />
    </Loader>
  )
}

export default Configuration
