import styled from 'styled-components'
import Avatar from '../common/Avatar/Avatar'

export const Background = styled.div<{ scrollPosition?: number }>`
  background-color: ${({ theme }) => theme.background};
  width: 100%;
  height: 100%;
  opacity: ${({ scrollPosition = 0 }) => scrollPosition / 100};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};  
  backdrop-filter: blur(20px);
`
export const Container = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  backdrop-filter: blur(5px);

`

export const Content = styled.div`
  width: 1100px;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex: 1;
  max-width: 90%;
  z-index: 10;
  margin: auto;
  justify-content: space-between;
`

export const Logo = styled.img`
  height: 40px;
  cursor: pointer;
`

export const CustomAvatar = styled(Avatar)`
  margin-left: 10px;
`
