import React, { forwardRef, useCallback, useState } from 'react'
import styled from 'styled-components'
import { setConstantValue } from 'typescript'
import Grid from '../../Grid/Grid'
import Icon from '../../Icon/Icon'
import Popper from '../../Popper/Popper'

const Container = styled.div<{ isFocused?: boolean }>`
  background-color: ${({ theme }) => theme.background10};
  border-radius: ${({ isFocused }) => (isFocused ? '10px 10px 0 0' : '10px')};
  position: relative;
  cursor: pointer;
  min-width: 200px;
  width: 100%;
`
const FakeInput = styled(Popper)<{ hasPrefix?: boolean; isFocused?: boolean }>`
  background-color: transparent !important;
  height: 60px;
  width: 100%;
  padding: 27px 60px 8px ${({ hasPrefix }) => (hasPrefix ? '60px' : '16px')};
  border-radius: ${({ isFocused }) => (isFocused ? '10px 10px 0 0' : '10px')};
  box-sizing: border-box;
  font-size: 14px;
  color: ${({ theme }) => theme.textPrimary};
  border: none;
  position: relative;
  z-index: 1;
  outline: rgba(0, 0, 0, 0);
`
const Label = styled.p<{ isFocused?: boolean; hasPrefix?: boolean }>`
  position: absolute;
  margin: 0;
  padding: 0 60px 0 ${({ hasPrefix }) => (hasPrefix ? '60px' : '16px')};
  height: 25px;
  transition: all 0.4s cubic-bezier(0.54, 0.1, 0, 0.99);
  top: ${({ isFocused }) => (isFocused ? '8px' : '50%')};
  transform: translateY(${({ isFocused }) => (isFocused ? '0' : '-50%')});
  font-size: ${({ isFocused }) => (isFocused ? '13px' : '17px')};
  color: ${({ theme }) => theme.textPlaceholder};
`
const COLORS = [
  '#161616',
  '#f10f0f',
  '#f10fa9',
  '#840ff1',
  '#0f1df1',
  '#0fcdf1',
  '#0ff159',
  '#ffd600',
  '#ff7e00',
  '#FFFFFF',
]
const ColorPrefix = styled.div<{ color: string }>`
  height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 10px;
  background-color: ${({ color }) => color};
`
const Prefix = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`
const Suffix = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`

const Asterisk = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.error};
`

const PopContent = styled.div`
  min-width: 200px;
  padding: 10px;
`

const ColorButton = styled.div<{ color?: string }>`
  height: 30px;
  width: 30px;
  min-width: 30px;
  background-color: ${({ color }) => color};
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.background20};

  &:hover {
    transform: scale(1.1);
    border-color: ${({ theme }) => theme.background30};
  }
`
export interface InputColorConfig {
  prefix?: any
  label: any
  required?: boolean
}

interface InputColorProps extends InputColorConfig {
  name: string
  value?: any
  onChange: (value?: any) => any
  hasError?: boolean
}
const InputColor = (
  { name, label, value, onChange, required }: InputColorProps,
  ref: any
) => {
  const [isFocused, setIsFocused] = useState(false)

  const changeValue = (v: any) => {
    onChange(v)
  }
  return (
    <Container>
      <Label isFocused={isFocused || !!value?.length} hasPrefix={true}>
        {label} {required ? <Asterisk>*</Asterisk> : null}
      </Label>
      <FakeInput
        ref={ref}
        isFocused={isFocused}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className="input-bg"
        trigger="click"
        hasPrefix={true}
        isVisible={isFocused}
        popContent={
          <PopContent>
            <Grid columns={5} space="5">
              {COLORS?.map((c) => (
                <ColorButton key={c} color={c} onClick={() => changeValue(c)} />
              ))}
            </Grid>
          </PopContent>
        }
      >
        <Prefix>
          {value ? <ColorPrefix color={value} /> : <Icon icon="palette" />}
        </Prefix>
        <Suffix>
          <Icon icon="angle-small-down" />
        </Suffix>
        {value}
      </FakeInput>
    </Container>
  )
}

export default forwardRef(InputColor)
