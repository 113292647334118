import React, { useEffect } from 'react'
import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 100%!important;
`
export const Error = styled.p`
  margin: 0;
  text-align: right;
  justify-content: flex-end;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.error};
  margin: 3px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
`
export const FormHeader= styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`

