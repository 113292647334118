import React from 'react'
import Row from '../common/Row/Row'
import Button from '../common/Button/Button'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import Text, { Title3 } from '../common/Text/Text'
import {
  Container,
  ContainerLanguages,
  Content,
  ContentLanguages,
  LanguageFooter,
  TextFooter,
  TitleFooter,
  TitleFooter5,
  Top,
} from './Footer.style'
import { useMediaQuery } from 'react-responsive'
import Col from '../common/Col/Col'

export const Footer = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return (
    <Container>
      <Content>
        <Row
          flex="1"
          justifyContent="center"
          alignItems="center"
          margin="10px 0 30px 0"
        >
          <Title3 type="3" color="textPrimary" margin="0">
            Start live with LicoGames.
          </Title3>
          <Button
            onClick={() => navigate('/games')}
            color="primary"
            margin="0 0 0 10px"
          >
            Get started
          </Button>
        </Row>
        <Row wrap justifyContent="space-between" alignItems="flex-start">
          <Col minWidth="200" margin="0 0 20px 0">
            <TitleFooter type="4">About us</TitleFooter>
            <TextFooter
              onClick={() => navigate('/about')}
              type="3"
              color="textSecondary"
              clickable
            >
              About
            </TextFooter>
            <TextFooter
              onClick={() => navigate('/contact')}
              type="3"
              color="textSecondary"
              clickable
            >
              Contact us
            </TextFooter>
          </Col>
          {/* <FooterColumn>
            <TitleFooter type="4">Help and support</TitleFooter>
            <TextFooter type="3" to="/about" color="textSecondary" clickable>
              FAQs
            </TextFooter>
            <TextFooter type="3" to="/coutact" color="textSecondary" clickable>
              Status
            </TextFooter>
          </FooterColumn>
          <FooterColumn>
            <TitleFooter1 type="4">Apps</TitleFooter1>
            <TextFooter type="3" color="textSecondary" clickable>
              LicoGames for Android
            </TextFooter>
            <TextFooter type="3" color="textSecondary" clickable>
              LicoGames for iOS
            </TextFooter>
            <TextFooter type="3" color="textSecondary" clickable>
              LicoGames for Web
            </TextFooter>
          </FooterColumn> */}
          <Col minWidth="200" margin="0 0 20px 0">
            <TitleFooter type="4">Privacy and terms</TitleFooter>
            <TextFooter
              onClick={() => navigate('/terms')}
              type="3"
              color="textSecondary"
              clickable
            >
              Terms
            </TextFooter>
            <TextFooter
              onClick={() => navigate('/privacy')}
              type="3"
              color="textSecondary"
              clickable
            >
              Privacy
            </TextFooter>
          </Col>
          <Col minWidth="200" margin="0 0 20px 0">
            <TitleFooter type="4">Social</TitleFooter>
            <TextFooter
              onClick={() =>
                window.open('https://www.tiktok.com/@licogames.off')
              }
              type="3"
              color="textSecondary"
              clickable
            >
              Tiktok
            </TextFooter>
            <TextFooter
              onClick={() =>
                window.open(
                  'https://www.youtube.com/channel/UCy06F7KJxzFYGaXeTDtByNg'
                )
              }
              type="3"
              color="textSecondary"
              clickable
            >
              Youtube
            </TextFooter>
            <TextFooter
              onClick={() =>
                window.open('https://www.instagram.com/licogames.off/')
              }
              type="3"
              color="textSecondary"
              clickable
            >
              Instagram
            </TextFooter>
          </Col>
        </Row>
      </Content>
      <ContainerLanguages>
        <ContentLanguages>
          <Row
            margin="0 10px"
            flex="1"
            alignItems="center"
            justifyContent="flex-start"
          >
            <TitleFooter5 type="5">Site language:</TitleFooter5>
            <Row margin="0 0 0 10px">
              <LanguageFooter
                onClick={() => i18n.changeLanguage('fr')}
                type="3"
                color="textSecondary"
                clickable
              >
                Français
              </LanguageFooter>
              <LanguageFooter
                onClick={() => i18n.changeLanguage('en')}
                type="3"
                color="textSecondary"
                clickable
              >
                English
              </LanguageFooter>
            </Row>
          </Row>
        </ContentLanguages>
      </ContainerLanguages>
    </Container>
  )
}

export default Footer
