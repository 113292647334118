import styled from 'styled-components'

export const FakeInput = styled.button<{
  hasPrefix?: boolean
  isFocused?: boolean
}>`
  background-color: transparent !important;
  height: 60px;
  width: 100%;
  padding: 27px 60px 8px ${({ hasPrefix }) => (hasPrefix ? '60px' : '16px')};
  border-radius: ${({ isFocused }) => (isFocused ? '10px 10px 0 0' : '10px')};
  box-sizing: border-box;
  font-size: 14px;
  color: ${({ theme }) => theme.textPrimary};
  border: none;
  position: relative;
  z-index: 1;
  outline: rgba(0, 0, 0, 0);
`
export const Label = styled.p<{ isFocused?: boolean; hasPrefix?: boolean }>`
  position: absolute;
  margin: 0;
  padding: 0 60px 0 ${({ hasPrefix }) => (hasPrefix ? '60px' : '16px')};
  height: 25px;
  transition: all 0.4s cubic-bezier(0.54, 0.1, 0, 0.99);
  top: ${({ isFocused }) => (isFocused ? '8px' : '50%')};
  transform: translateY(${({ isFocused }) => (isFocused ? '0' : '-50%')});
  font-size: ${({ isFocused }) => (isFocused ? '13px' : '17px')};
  color: ${({ theme }) => theme.textPlaceholder};
`

export const Prefix = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`
export const Suffix = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`

export const Asterisk = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.error};
`

export const Value = styled.div<{ hasPrefix?: boolean }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  position: absolute;
  text-align: left;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 27px 60px 8px ${({ hasPrefix }) => (hasPrefix ? '60px' : '16px')};
`

export const Container = styled.div<{
  isFocused?: boolean
  hasError?: boolean
}>`
  background-color: ${({ theme }) => theme.background10};
  border-radius: ${({ isFocused }) => (isFocused ? '10px 10px 0 0' : '10px')};
  position: relative;
  cursor: pointer;
  min-width: 200px;
  width: 100%;
  box-shadow: ${({ hasError, theme }) =>
    hasError ? ` ${theme.error} 0px 0px 0px 3px` : ''};
  ${Suffix} *, ${Prefix} *, ${Label} {
    color: ${({ theme, hasError }) => (hasError ? theme.error : '')}!important;
  }
`
