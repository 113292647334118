import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import Modal, { ModalProps } from '../components/common/Modal/Modal'
import { useTranslation } from 'react-i18next'
import Grid from '../components/common/Grid/Grid'
import Row from '../components/common/Row/Row'
import Button from '../components/common/Button/Button'
import AuthContext from '../contexts/AuthContext'
import GiftServices from '../services/data/gift'
import Text from '../components/common/Text/Text'

const Content = styled.div`
  width: 600px;
`

const GiftImage = styled.img<{ selected?: boolean }>`
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  border: ${({ selected, theme }) =>
    selected ? '4px solid ' + theme.primary : '4px solid transparent'};
`
const File = styled.div`
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
`

interface ModalGiftManagerProps extends ModalProps {
  value?: any[]
  onChange?: (value: any) => any
}
const ModalGiftManager = (props: ModalGiftManagerProps) => {
  const authCtx = useContext(AuthContext)
  const { t } = useTranslation()
  const [selected, setSelected] = useState(props?.value || [])

  const assets = GiftServices.useFetchGifts()

  const onGiftClick = (e: React.MouseEvent, gift: any) => {
    e.stopPropagation()
    if (selected.filter(item => item.id === gift.id).length > 0) {
      setSelected(selected.filter(item => item.id !== gift.id))
    } else {
      setSelected(oldSelected => [...oldSelected, gift])
    }
  }

  const onUseSelection = () => {
    props.onChange?.(selected)
    props.close?.()
  }

  return (
    <Modal
      {...props}
      title={'Choose gifts'}
      footer={
        <Row alignItems="center" justifyContent="flex-end">
          <Button margin="0 0 0 10px" color="default" disabled={selected.length === 0} onClick={() => setSelected([])}>
            Reset
          </Button>
          <Button margin="0 0 0 10px" color="primary" disabled={selected.length === 0} onClick={() => onUseSelection()}>
            Use selection
          </Button>
        </Row>
      }
    >
      <Content>
        <Grid columns="5" onClick={() => props.onChange?.([])}>
          {assets?.data?.sort((a: any, b: any) => a.price - b.price).map((gift: any, index: number) => (
            <File key={index} onClick={(e) => onGiftClick(e, gift)}>
              <GiftImage
                src={gift.url}
                selected={selected.filter(item => item.id === gift.id).length > 0}/>
              <Text>{gift.name} Price: {gift.price}</Text>
            </File>
          ))}
        </Grid>
      </Content>
    </Modal>
  )
}

export default ModalGiftManager
