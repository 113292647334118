import React from 'react'
import { Title4 } from '../../../components/common/Text/Text'
import Icon from '../../../components/common/Icon/Icon'
import { SessionEditable } from '../../../services/data/session'
import { Field } from '../../../components/common/FormBuilder/FormBuilder.types'
import Alert from '../../../components/common/Alert/Alert'
import useConfigureSession from '../../../hooks/useConfigureSession'
import VotePreview from './VotePreview/VotePreview'
import Configuration from '../../../components/Configuration/Configuration'
import UsersServices from '../../../services/data/user'

interface Vote {
  title: string
  elements: Array<{ image: string; name: string }>
}

const INITIAL_Vote: SessionEditable<Vote> = {
  name: '',
  data: {
    title: '',
    elements: [],
  },
}

const ConfigurationVote = () => {
  const session = useConfigureSession<Vote>(INITIAL_Vote, 'Vote')
  const step1: Field[] = [
    {
      name: 'name',
      type: 'text',
      rules: { required: true },
      config: {
        label: 'Name of the session',
      },
    },
    {
      name: 'data',
      type: 'object',
      config: {
        children: [
          {
            name: 'title',
            type: 'text',
            rules: { required: true },
            config: {
              label: 'Title',
            },
          },
          {
            name: 'first-option',
            type: 'html',
            config: {
              children: <Title4>Options</Title4>,
            },
          },
          {
            type: 'array',
            name: 'options',
            config: {
              editable: true,
              children: {
                type: 'object',
                config: {
                  children: [
                    {
                      name: 'name',
                      type: 'text',
                      rules: { required: true },
                      config: {
                        label: 'Name',
                      },
                    },
                    {
                      name: 'image',
                      type: 'filePicker',
                      rules: { required: true },
                      config: {
                        prefix: <Icon icon="picture" />,
                        label: 'Image',
                      },
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    },
  ]
  const steps: Array<Field[]> = [step1]
  return (
    <Configuration steps={steps} session={session}>
      {({ data }: any) => <VotePreview {...data} />}
    </Configuration>
  )
}

export default ConfigurationVote
