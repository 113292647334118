import countries, { CountriesISO } from './countries'
import { Word } from './types'

export type { CountriesISO }
export type { Word }
export { countries }

export const breakpoints = {
  mobileS: '0px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
  desktopL: '2560px',
}

export const mediaQueries = {
  mobileS: `(max-width: ${breakpoints.mobileS})`,
  mobileM: `(max-width: ${breakpoints.mobileM})`,
  mobileL: `(max-width: ${breakpoints.mobileL})`,
  tablet: `(max-width: ${breakpoints.tablet})`,
  laptop: `(max-width: ${breakpoints.laptop})`,
  laptopL: `(max-width: ${breakpoints.laptopL})`,
  desktop: `(max-width: ${breakpoints.desktop})`,
  desktopL: `(max-width: ${breakpoints.desktopL})`,
}
