import React from 'react'
import SessionPreview from '../../../components/SessionPreview/SessionPreview'
import { GAMES } from '../../../utils/games'
import VotePreview from './VotePreview/VotePreview'


const VoteSessionPreview = () => {
  const score1 = 0
  const score2 = 0
  return (
    <SessionPreview activityType={GAMES.VOTE}>
      {(data: any) => <VotePreview score1={0} score2={0} {...data} />}
    </SessionPreview>
  )
}

export default VoteSessionPreview
