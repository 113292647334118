import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Field } from '../FormBuilder/FormBuilder.types'
import FormFieldArray from '../FormFieldArray/FormFieldArray'
import FormFieldObject from '../FormFieldObject/FormFieldObject'
import InputColor from '../Input/InputColor/InputColor'
import InputFilePicker from '../Input/InputFilePicker/InputFilePicker'
import InputSelect from '../Input/InputSelect/InputSelect'
import InputText from '../Input/InputText/InputText'
import InputTextArea from '../Input/InputTextArea/InputTextArea'
import { Container } from './FormItem.style'
import InputGiftPicker from '../Input/InputGiftPicker/InputGiftPicker'
import InputCheckbox from '../Input/InputCheckbox/InputCheckbox'

const FormItem = (props: Field) => {
  const {
    formState: { errors },
    control,
    watch,
    getValues,
  } = useFormContext()

  const renderInput = (field: any, fieldState: any, controls: any) => {
    switch (props.type) {
      case 'text':
        return (
          <InputText
            hasError={!!fieldState?.error}
            {...props.config}
            {...field}
          />
        )
      case 'checkbox':
        return (
          <InputCheckbox
            hasError={!!fieldState?.error}
            {...props.config}
            {...field}
          />
        )
      case 'textarea':
        return (
          <InputTextArea
            {...field}
            hasError={!!fieldState?.error}
            {...props.config}
            {...control}
          />
        )
      case 'select':
        return (
          <InputSelect
            {...field}
            hasError={!!fieldState?.error}
            {...props.config}
            {...control}
          />
        )
      case 'filePicker':
        return (
          <InputFilePicker
            {...field}
            hasError={!!fieldState?.error}
            {...props.config}
            {...control}
          />
        )
      case 'giftPicker':
        return (
          <InputGiftPicker
            {...field}
            hasError={!!fieldState?.error}
            {...props.config}
            {...control}
          />
        )
      case 'color':
        return (
          <InputColor
            {...field}
            hasError={!!fieldState?.error}
            {...props.config}
            {...control}
          />
        )
      case 'object':
        return <FormFieldObject {...field} {...props} />
      case 'array':
        return (
          <FormFieldArray
            {...field}
            ref={undefined}
            config={props.config}
            hasError={!!fieldState?.error}
            control={control}
          />
        )
    }
  }
  const condition = props?.condition ? props.condition?.() : true
  return (
    <>
      {condition && (
        <Container>
          {props.type !== 'html' ? (
            <Controller
              rules={(props as any)?.rules ?? undefined}
              name={(props as any)?.name}
              control={control}
              defaultValue=""
              render={({ field, fieldState }: any) => (
                <>{renderInput(field, fieldState, control)}</>
              )}
            />
          ) : (
            props?.config?.children
          )}
        </Container>
      )}
    </>
  )
}

export default FormItem
