import React, { useEffect, useState } from 'react'
import Button from '../Button/Button'
import Col from '../Col/Col'
import FormBuilder from '../FormBuilder/FormBuilder'
import { Field } from '../FormBuilder/FormBuilder.types'
import Icon from '../Icon/Icon'
import Row from '../Row/Row'
import { Container, StepsIndicator } from './FormStepBuilder.style'

interface FormStepBuilderProps {
  steps: Array<Field[]>
  maxWidth?: string
  defaultValues?: any
  onSubmit: (value: any) => void
  onChange?: (v: any) => any
  preview?: (v: any) => any
}
const FormStepBuilder = ({
  steps,
  maxWidth,
  defaultValues,
  onSubmit,
  onChange,
  preview,
}: FormStepBuilderProps) => {
  const [index, setIndex] = useState(0)
  const [data, setData] = useState(defaultValues as any)
  const [maxIndex, setMaxIndex] = useState(0)

  const length = steps?.length + (preview ? 1 : 0)
  const isLastStep = index === length - 1
  const isFirstStep = index === 0

  useEffect(() => {
    if (maxIndex < index) {
      setMaxIndex(index)
    }
  }, [index])

  const onFormChange = (v: any) => {
    setData(v)
    onChange?.(v)
  }

  return (
    <Container maxWidth={maxWidth}>
      <StepsIndicator
        length={length}
        current={index}
        onClick={(v) => setIndex(v)}
        maxIndex={maxIndex}
      />
      {index < steps?.length ? (
        <FormBuilder
          key={'' + index}
          fields={steps[index]}
          onChange={onFormChange}
          defaultValues={data}
          onSubmit={(v: any) => {
            setIndex(index + 1)
          }}
          footer={
            <Row alignItems="center" justifyContent="flex-end">
              {!isFirstStep && (
                <Button
                  type="button"
                  onClick={() => setIndex(index - 1)}
                  margin="0 0 0 10px"
                >
                  <Icon icon="angle-left" />
                </Button>
              )}
              {index < maxIndex && (
                <Button
                  type="button"
                  onClick={() => setIndex(index + 1)}
                  margin="0 0 0 10px"
                >
                  <Icon icon="angle-right" />
                </Button>
              )}
              <Button type="submit" color="primary" margin="0 0 0 10px">
                {isLastStep ? 'Submit' : 'Next step'}
              </Button>
            </Row>
          }
        />
      ) : (
        <Col alignItems="center" justify-content="center">
          {preview?.(data)}
          <Row alignItems="center" justifyContent="flex-end">
            <Button
              type="button"
              onClick={() => setIndex(index - 1)}
              margin="0 0 0 10px"
            >
              <Icon icon="angle-left" />
            </Button>
            <Button
              type="submit"
              onClick={() => onSubmit(data)}
              color="primary"
              margin="20px 0 20px 10px"
            >
              Submit
            </Button>
          </Row>
        </Col>
      )}
    </Container>
  )
}

export default FormStepBuilder
