import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import fr from '../src/assets/i18n/fr.json'
import en from '../src/assets/i18n/en.json'
import homeEn from '../src/assets/i18n/home/en.json'
import homeFr from '../src/assets/i18n/home/fr.json'

const resources = {
    en: {
        home: homeEn,
        translation: en
    },
    fr: {
        home: homeFr,
        translation: fr
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        interpolation: {
            escapeValue: false
        }
    })

export default i18n
