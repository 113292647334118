import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  OAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
} from 'firebase/auth'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { useContext } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import AuthContext from '../contexts/AuthContext'
import { auth, db } from '../firebase'

const googleProvider = new GoogleAuthProvider()
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
    const user = res.user
    const q = query(collection(db, 'users'), where('uid', '==', user.uid))
    const docs = await getDocs(q)
    if (docs.docs.length === 0) {
      const docRef = doc(db, 'users', user.uid)
      await setDoc(docRef, {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
        photoURL: user.photoURL,
      })
    }
  } catch (err: any) {
    alert(err.message)
  }
}

const appleProvider = new OAuthProvider('apple.com')
const signInWithApple = async () => {
  try {
    const res = await signInWithPopup(auth, appleProvider)
    const user = res.user
    const q = query(collection(db, 'users'), where('uid', '==', user.uid))
    const docs = await getDocs(q)
    if (docs.docs.length === 0) {
      const docRef = doc(db, 'users', user.uid)
      await setDoc(docRef, {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'apple',
        email: user.email,
      })
    }
  } catch (err: any) {
    alert(err.message)
  }
}

interface EmailAndPassword {
  email: string
  password: string
}

export const useCreateUserWithEmailAndPassword = () => {
  return useMutation(async ({ email, password }: EmailAndPassword) => {
    await createUserWithEmailAndPassword(getAuth(), email, password)
  })
}

export const useSignInWithEmailAndPassword = () => {
  return useMutation(async ({ email, password }: EmailAndPassword) => {
    await signInWithEmailAndPassword(getAuth(), email, password)
  })
}

export const useSignInWithGoogle = () => {
  return useMutation(async () => {
    try {
      return await signInWithGoogle()
    } catch (err) {
      toast.error('An error occur!')
      throw err
    }
  })
}

export const useSignInWithApple = () => {
  return useMutation(async () => {
    try {
      return await signInWithApple()
    } catch (err) {
      toast.error('An error occur!')
      throw err
    }
  })
}

const updateUserInformations = async ({
  displayName,
  description,
  email,
  phoneNumber,
}: {
  displayName?: string
  description?: string
  email?: string
  phoneNumber?: string
}) => {
  try {
    const auth = getAuth()
    if (!auth?.currentUser) {
      throw new Error('No user logged in!')
    }
    await updateProfile(auth.currentUser, {
      displayName,
    })
    await updateUserProfile(auth.currentUser.uid, {
      displayName,
      description,
      email,
      phoneNumber,
    })
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const useUpdateUserInformations = () => {
  return useMutation(updateUserInformations)
}

const updateUserSocialAccounts = async ({
  socialAccounts,
}: {
  socialAccounts?: any
}) => {
  try {
    const auth = getAuth()
    if (!auth?.currentUser) {
      throw new Error('No user logged in!')
    }

    await updateUserProfile(auth.currentUser.uid, {
      socialAccounts,
    })
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const useUpdateUserSocialAccounts = () => {
  return useMutation(updateUserSocialAccounts)
}

const updateUserProfile = async (userId: string, data: any) => {
  const docRef = doc(db, 'users', userId)
  return await updateDoc(docRef, data)
}
interface useUpdateAvatarProps {
  newAvatar?: any
}
export const useUpdateAvatar = () => {
  const authCtx = useContext(AuthContext)

  return useMutation(async ({ newAvatar }: useUpdateAvatarProps) => {
    try {
      const currentUser = auth.currentUser
      if (!currentUser) {
        throw new Error('there is no logged user')
      }
      if (newAvatar) {
        const blob = await (await fetch(newAvatar))?.blob()

        const storageRefTmp = ref(getStorage(), `avatars/${authCtx.user?.uid}`)
        const uploadRes = await uploadBytes(storageRefTmp, blob)
        const photoURL = await getDownloadURL(uploadRes.ref)
        await updateProfile(currentUser, { photoURL })
        await updateUserProfile(currentUser.uid, { photoURL })
      } else {
        await updateProfile(currentUser, { photoURL: '' })
        await updateUserProfile(currentUser.uid, { photoURL: '' })
      }
    } catch (err) {
      console.log(err)
    }
  })
}
export const fetchUserData = async (id = '') => {
  try {
    const userRef = doc(db, 'users', id)
    const userSnap = await getDoc(userRef)
    if (userSnap.exists()) {
      return userSnap.data()
    }
    return null
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

const userPublicInformations = async (id = '') => {
  try {
    const userRef = doc(db, 'users', id)
    const userSnap = await getDoc(userRef)
    if (userSnap.exists()) {
      return userSnap.data()
    }
    return null
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
export const useUserPublicInformations = (id?: string) => {
  return useQuery('user' + id, async () => userPublicInformations(id), {
    enabled: !!id,
  })
}
