import React, { useCallback, useState } from 'react'
import ReactDOM from 'react-dom'
import {
  Container,
  Backdrop,
  Wrapper,
  Position,
  Content,
  Header,
  Title,
  CloseButton,
  CloseIcon,
  Body,
  Footer,
} from './Modal.style'

export const useModal = (defaultVisibleValue = false) => {
  const [visible, setVisible] = useState(defaultVisibleValue)
  const close = useCallback(() => {
    setVisible(false)
  }, [])

  const open = useCallback(() => {
    setVisible(true)
  }, [])
  return { visible, close, open }
}

export interface ModalProps {
  title?: string
  children?: any
  footer?: any
  visible?: boolean
  close?: () => any
  closable?: boolean
}

const Modal = ({
  title,
  children,
  footer,
  visible,
  close,
  closable = true,
}: ModalProps) => {
  const onClose = (e: any) => {
    if (closable) {
      e.stopPropagation()
      if (close) {
        close()
      }
    }
  }
  return (
    <>
      {visible &&
        ReactDOM.createPortal(
          <Container>
            <Backdrop onClick={close} />
            <Wrapper>
              <Position>
                <Content>
                  <Header hasBorder={!!title}>
                    <Title>{title}</Title>
                    <CloseButton
                      className="LyModalContentHeaderCloseButton"
                      onClick={onClose}
                    >
                      <CloseIcon icon="cross" />
                    </CloseButton>
                  </Header>
                  {!!children && <Body>{children}</Body>}
                  {!!footer && <Footer>{footer}</Footer>}
                </Content>
              </Position>
            </Wrapper>
          </Container>,
          document.getElementById('modal-root') as any
        )}
    </>
  )
}

export default Modal
