import React from 'react'
import styled from 'styled-components'
import FormBuilder from '../components/common/FormBuilder/FormBuilder'
import Icon from '../components/common/Icon/Icon'
import { useCreateUserWithEmailAndPassword } from '../services/auth'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Modal, { ModalProps } from '../components/common/Modal/Modal'
import { useTranslation } from 'react-i18next'
import { Field } from '../components/common/FormBuilder/FormBuilder.types'

const Separator = styled.div`
  height: 10px;
`
const Title = styled.h3`
  font-size: 35px;
  margin: 0 10px;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.textPrimary};
`
const Text = styled.p`
  font-size: 17px;
  font-weight: 400;
  color: ${({ theme }) => theme.textSecondary};
  text-align: center;
`
const Message = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.textPrimary};
  text-align: center;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
`
const Container = styled.div`
  max-width: 350px;
  width: 100;
  display: flex;
  flex-direction: column;
  margin-top: -20px;
`

const CustomLink = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.primary};
`
interface ModalRegisterProps extends ModalProps {
  onGoLogin: () => any
}
const ModalRegister = (props: ModalRegisterProps) => {
  const { t, i18n } = useTranslation()
  const createUserWithEmailAndPassword = useCreateUserWithEmailAndPassword()

  const onSubmit = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    const res = await createUserWithEmailAndPassword.mutate({
      email,
      password,
    })
  }
  const fields: Field[] = [
    {
      type: 'text',
      name: 'email',
      config: {
        label: t('modals.modalRegister.fields.email'),
        prefix: <Icon icon="envelope" />,
      },
    },
    {
      type: 'text',
      name: 'password',
      rules: { required: { value: true, message: 'This field is required' } },
      config: {
        label: t('modals.modalRegister.fields.password'),
        prefix: <Icon icon="lock" />,
        isPassword: true,
      },
    },
    {
      type: 'text',
      name: 'passwordConfirm',
      rules: { required: { value: true, message: 'This field is required' } },
      config: {
        label: t('modals.modalRegister.fields.passwordConfirmation'),
        prefix: <Icon icon="lock" />,
        isPassword: true,
      },
    },
  ]
  const formSchema = yup.object().shape({
    password: yup
      .string()
      .required(t('modals.modalRegister.passwordRequired'))
      .min(8, t('modals.modalRegister.passwordLength')),
    passwordConfirm: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password')], t('modals.modalRegister.passwordMatch')),
    email: yup
      .string()
      .email()
      .required(t('modals.modalRegister.emailRequired')),
  })
  const resolver = yupResolver(formSchema)
  return (
    <Modal {...props}>
      <Container>
        <Title>{t('modals.modalRegister.signUp')}</Title>
        <Text>{t('modals.modalRegister.signUpWithEmail')}</Text>
        <FormBuilder
          fields={fields}
          onSubmit={onSubmit}
          isLoading={createUserWithEmailAndPassword?.isLoading}
          config={{ resolver }}
        />
        <Separator />
        <Message>
          {t('modals.modalRegister.forgotPassword')}
          <CustomLink>{t('modals.modalRegister.resetPassword')}</CustomLink>
        </Message>
        <Separator />
        <Message onClick={() => props.onGoLogin()}>
          {t('modals.modalRegister.alreadyAccount')}
          <CustomLink>{t('modals.modalRegister.signIn')}</CustomLink>
        </Message>
        <Separator />
      </Container>
    </Modal>
  )
}

export default ModalRegister
