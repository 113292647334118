import React from 'react'
import styled from 'styled-components'

export const View = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.background};
`

export const Content = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 50px 0;
`
