import React, { useState, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import Icon from '../../Icon/Icon'

export const Label = styled.div`
  min-height: 60px;
  padding: 18px 16px 18px 0;
  transition: all 0.4s cubic-bezier(0.54, 0.1, 0, 0.99);
  font-size: 17px;
  line-height: 24px;
  display: flex;
  box-sizing: border-box;
  flex: 1;
  color: ${({ theme }) => theme.textPlaceholder};
`
export const Prefix = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Checkbox = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.borderColor};

  * {
    color: #ffffff;
  }
`
export const Container = styled.div<{
  isFocused?: boolean
  hasError?: boolean
  checked?: boolean
}>`
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  width: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: ${({ theme, hasError }) => theme.background10};
  box-shadow: ${({ hasError, theme }) =>
    hasError ? ` ${theme.error} 0px 0px 0px 3px` : ''};
  ${Label} {
    ${({ hasError, theme }) =>
      hasError ? theme.error : theme.textPlaceholder};
  }
  ${Checkbox} {
    ${({ checked, theme }) => {
      return css`
        background-color: ${checked ? theme.success : ''};
      `
    }}
  }
`
