import React from 'react'
import styled from 'styled-components'
import { CountriesISO } from '../../../utils'

interface FlagProps {
  flag: CountriesISO
  size?: number
}

const FlagImg = styled.img<{ size?: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`
const Flag = ({ flag, size = 17 }: FlagProps) => {
  return (
    <FlagImg src={require(`../../../assets/flags/${flag}.png`)} size={size} />
  )
}
export default Flag
