import React from 'react'
import Icon from '../Icon/Icon'
import Menu, { MenuItemProps } from '../Menu/Menu'
import Popper from '../Popper/Popper'
import Tooltip from '../Tooltip/Tooltip'
import {
  Body,
  ButtonContainer,
  ButtonIcon,
  ButtonType,
  Container,
  Content,
  Label,
  LoadingContainer,
  Prefix,
  Suffix,
} from './Button.style'

interface ContainerProps {
  color?: ButtonType
  isLoading?: boolean
  expand?: boolean
}
interface ButtonProps {
  children?: any
  onClick?: (e: any) => any
  color?: ButtonType
  className?: any
  prefix?: any
  suffix?: any
  isLoading?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  expand?: boolean
  actions?: MenuItemProps[]
  tooltip?: any
  popContent?: any
  margin?: string
}

export const Button = ({
  actions,
  expand,
  color,
  onClick,
  className,
  isLoading,
  disabled,
  suffix,
  prefix,
  children,
  type = 'button',
  popContent,
  margin,
  ...props
}: ButtonProps) => {
  return (
    <ButtonContainer expand={expand} margin={margin}>
      <Tooltip content={props.tooltip}>
        <Popper
          trigger="click"
          popContent={
            popContent ??
            (actions?.length && (
              <Menu>
                {actions?.map((action: any, index: number) => {
                  return (
                    <Menu.Item
                      {...action}
                      key={index}
                      isLoading={action.isLoading}
                    >
                      {action.children}
                    </Menu.Item>
                  )
                })}
              </Menu>
            ))
          }
        >
          <Container
            expand={expand}
            type={type}
            color={color}
            onClick={onClick}
            className={className}
            isLoading={isLoading}
            disabled={disabled || isLoading}
          >
            <Content isLoading={isLoading} className="button-content">
              {(!!suffix || !!prefix) && <Prefix>{prefix}</Prefix>}
              <Body>{children}</Body>
              {(!!suffix || !!prefix) && <Suffix>{suffix}</Suffix>}
            </Content>
            <LoadingContainer isLoading={isLoading}>
              <Icon icon="loading" spin={isLoading} />
            </LoadingContainer>
          </Container>
        </Popper>
      </Tooltip>
    </ButtonContainer>
  )
}
Button.Prefix = Prefix
Button.Suffix = Suffix
Button.Label = Label
Button.Icon = ButtonIcon

export default Button
