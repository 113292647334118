import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AuthContext from '../../contexts/AuthContext'
import ActivitiesServices from '../../services/data/activites'
import SessionServices from '../../services/data/session'
import Collapse, {
  useCollapse,
} from '../../components/common/Collapse/Collapse'
import Text, { Title } from '../../components/common/Text/Text'
import Icon from '../../components/common/Icon/Icon'
import { Field } from '../../components/common/FormBuilder/FormBuilder.types'
import FormBuilder from '../../components/common/FormBuilder/FormBuilder'
import Row from '../../components/common/Row/Row'
import Button from '../../components/common/Button/Button'
import Col from '../../components/common/Col/Col'
import styled from 'styled-components'
import { useModal } from '../../components/common/Modal/Modal'
import ModalAddAccount from '../../modals/ModalAddAccount'
import UsersServices from '../../services/data/user'
import { toast } from 'react-toastify'
import { useUpdateUserInformations } from '../../services/auth'
import ContactServices from '../../services/divers/contact'
import { View, Content } from './Contact.style'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

const Contact = () => {
  const { t } = useTranslation()
  const contactRequest = ContactServices.useNewContact()
  const [sent, setSent] = useState(false)
  const submit = (data: any) => {
    contactRequest.mutate(
      {
        email: data.email,
        message: data.message,
      },
      {
        onSuccess: async () => {
          setSent(true)
          await toast.success('Your message has been sent !')
        },
        onError: async () =>
          await toast.error('Error while sending the message !'),
      }
    )
  }
  const fields: Field[] = [
    {
      type: 'text',
      name: 'email',
      config: {
        label: 'Email',
        prefix: <Icon icon="at" />,
      },
    },
    {
      type: 'textarea',
      name: 'message',
      config: {
        label: 'Message',
        prefix: <Icon icon="text" />,
      },
    },
  ]

  return (
    <View>
      <Header />
      {!sent && (
        <Content>
          <Col alignItems="center" justifyContent="center" margin="0 0 50px 0">
            <Title textAlign="center">Contact Us</Title>
            <Text textAlign="center">
              Fell free to contact us if you have any questions or requirements.
            </Text>
          </Col>
          <FormBuilder onSubmit={submit} fields={fields} />
        </Content>
      )}
      {sent && (
        <Content>
          <Col alignItems="center" justifyContent="center" margin="0 0 50px 0">
            <Title textAlign="center">Contact Us</Title>
            <Text textAlign="center">
              Your message has been sent. We will answer you shortly !
            </Text>
          </Col>
        </Content>
      )}
      <Footer />
    </View>
  )
}

export default Contact
