import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import Icon from '../components/common/Icon/Icon'
import {
  useSignInWithApple,
  useSignInWithEmailAndPassword,
  useSignInWithGoogle,
} from '../services/auth'
import FormBuilder from '../components/common/FormBuilder/FormBuilder'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Modal, { ModalProps } from '../components/common/Modal/Modal'
import Button from '../components/common/Button/Button'
import google from '../assets/google.png'
import apple from '../assets/apple.png'
import AuthContext from '../contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import { Field } from '../components/common/FormBuilder/FormBuilder.types'

const Separator = styled.div`
  height: 10px;
`
const Title = styled.h3`
  font-size: 35px;
  margin: 0 10px;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.textPrimary};
`
const Text = styled.p`
  font-size: 17px;
  font-weight: 400;
  color: ${({ theme }) => theme.textSecondary};
  text-align: center;
`
const Message = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.textPrimary};
  text-align: center;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
`
const Container = styled.div`
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -20px;
`

const CustomLink = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.primary};
`
const ButtonImage = styled.img`
  height: 20px;
`
interface ModalLoginProps extends ModalProps {
  onGoRegister: () => any
}
const ModalLogin = (props: ModalLoginProps) => {
  const authCtx = useContext(AuthContext)
  const { t, i18n } = useTranslation()
  const signInWithEmailAndPassword = useSignInWithEmailAndPassword()
  const signInWithGoogle = useSignInWithGoogle()
  const signInWithApple = useSignInWithApple()

  useEffect(() => {
    props.close?.()
  }, [authCtx?.user])
  const onSubmit = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    const res = await signInWithEmailAndPassword.mutate({
      email,
      password,
    })
  }
  const fields: Field[] = [
    {
      type: 'text',
      name: 'email',
      config: {
        label: t('modals.modalLogIn.fields.email'),
        prefix: <Icon icon="envelope" />,
      },
    },
    {
      type: 'text',
      name: 'password',
      config: {
        label: t('modals.modalLogIn.fields.password'),
        prefix: <Icon icon="lock" />,
        isPassword: true,
      },
    },
  ]
  const formSchema = yup.object().shape({
    password: yup
      .string()
      .required(t('modals.modalLogIn.passwordRequired'))
      .min(8, t('modals.modalLogIn.passwordLength')),
    email: yup.string().email().required(t('modals.modalLogIn.emailRequired')),
  })
  const resolver = yupResolver(formSchema)
  return (
    <Modal {...props}>
      <Container>
        <Title>{t('modals.modalLogIn.login')}</Title>
        <Button
          margin="10px 0"
          expand
          color="contrast"
          prefix={<ButtonImage src={google} />}
          suffix={<Icon icon="angle-small-right" />}
          isLoading={signInWithGoogle?.isLoading}
          onClick={() => signInWithGoogle.mutate()}
        >
          {t('modals.modalLogIn.continueGoogle')}
        </Button>
        <Button
          margin="0 0 20px 0"
          expand
          color="contrast"
          prefix={<ButtonImage src={apple} />}
          suffix={<Icon icon="angle-small-right" />}
          isLoading={signInWithApple?.isLoading}
          onClick={() => signInWithApple.mutate()}
        >
          {t('modals.modalLogIn.continueApple')}
        </Button>

        <Text>{t('modals.modalLogIn.loginWithEmail')}</Text>
        <FormBuilder
          fields={fields}
          onSubmit={onSubmit}
          isLoading={signInWithEmailAndPassword?.isLoading}
          config={{ resolver }}
        />
        <Separator />
        <Message>
          {t('modals.modalLogIn.forgotPassword')}
          <CustomLink>{t('modals.modalLogIn.resetPassword')}</CustomLink>
        </Message>
        <Separator />
        <Message onClick={() => props.onGoRegister()}>
          {t('modals.modalLogIn.noAccount')}
          <CustomLink>{t('modals.modalLogIn.signup')}</CustomLink>
        </Message>
        <Separator />
      </Container>
    </Modal>
  )
}

export default ModalLogin
