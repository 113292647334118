import React, {useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import SessionServices from '../../services/data/session'
import Button from '../common/Button/Button'
import Icon from '../common/Icon/Icon'
import Loader from '../common/Loader/Loader'
import Row from '../common/Row/Row'
import Preview from '../Preview/Preview'
import UsersServices from '../../services/data/user'
import { GAMES } from '../../utils/games'

export const ArrowIcon = styled(Icon)`
  transition: all 0.2s cubic-bezier(0.5, -0.01, 0.76, 1);
  color: #ffffff;
  margin-left: 10px;
`

export const BeginButton = styled(Button)`
  :hover {
    ${ArrowIcon} {
      transform: translateX(5px);
    }
  }
`
const Bold = styled.span`
  font-weight: 800;
  margin-left: 10px;
`

interface SessionPreviewProps {
  children: any
  activityType: GAMES
}
const SessionPreview = ({ children, activityType }: SessionPreviewProps) => {
  const navigate = useNavigate()
  const { sessionId } = useParams() as any
  const session = SessionServices.useFetchSession(sessionId)
  const user = UsersServices.useFetchUser()
  const [currentAccount, setCurrentAccount] = useState()

  useEffect(() => {
    setCurrentAccount(user?.data?.socialAccounts?.[0]?.username)
  }, [user.data])

  const onEdit = () => {
    navigate(`/games/${activityType}/configuration/${sessionId}`)
  }

  const onStart = () => {
    navigate(`/games/${activityType}/session/${sessionId}/${currentAccount}`)
  }
  return (
    <Loader isLoading={session?.isLoading}>
      <Row alignItems="center" justifyContent="center" margin="20px">
        <Button onClick={onEdit}>
          <Button.Icon icon="pencil" />
          Edit session
        </Button>
        <BeginButton margin="0 0 0 10px" onClick={onStart} color="primary">
          Start live
          <ArrowIcon icon="angle-small-right" />
        </BeginButton>
      </Row>
      <Preview>{children(session?.data?.data)}</Preview>
    </Loader>
  )
}

export default SessionPreview
