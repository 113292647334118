import React from 'react'
import styled, { css } from 'styled-components'

interface TextProps extends Record<string, any> {
  color?: any
  fontWeight?:
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
  textAlign?: 'left' | 'right' | 'center'
  margin?: string
}

export const Title = styled.h1<TextProps>`
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ color, theme }) =>
    (theme as any)[color] || color || theme.textPrimary};
  font-size: 55px;
  font-weight: ${({ fontWeight }) => fontWeight || '600'};
  margin: ${({ margin }) => margin ?? '50px 0'}
`
export const Title2 = styled.h2<TextProps>`
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ color, theme }) =>
    (theme as any)[color] || color || theme.textPrimary};
  font-size: 35px;
  font-weight: 600;
  margin: ${({ margin }) => margin ?? '30px 0'};
`
export const Title3 = styled.h3<TextProps>`
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ color, theme }) =>
    (theme as any)[color] || color || theme.textPrimary};
  font-size: 25px;
  font-weight: 600;
  margin: ${({ margin }) => margin ?? '15px 0'};
`
export const Title4 = styled.h4<TextProps>`
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ color, theme }) =>
    (theme as any)[color] || color || theme.textPrimary};
  font-size: 20px;
  margin: ${({ margin }) => margin ?? '10px 0'};
  font-weight: 600;
`
export const Title5 = styled.h4<TextProps>`
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ color, theme }) =>
    (theme as any)[color] || color || theme.textPrimary};
  font-size: 15px;
  margin:${({ margin }) => margin ?? '10px 0'};
  font-weight: 600;
`
interface SimpleTextProps extends TextProps {
  type?: '1' | '2' | '3' | '4'
  clickable?: boolean
  margin?: string
}
const Text = styled.p<SimpleTextProps>`
  font-size: 19px;
  font-weight: 400;
  width: fit-content;
  display: inline-block;
  position: relative;
  margin: ${({ margin }) => margin || 0};
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ color, theme }) =>
    (theme as any)[color] || color || theme.textPrimary};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  ${({ type = '1' }) => {
    switch (type) {
      case '1':
        return css`
          font-size: 19px;
          line-height: 30px;
        `
      case '2':
        return css`
          font-size: 17px;
          line-height: 27px;
        `
      case '3':
        return css`
          font-size: 15px;
          line-height: 25px;
        `
      case '4':
        return css`
          font-size: 13px;
          line-height: 20px;
        `
    }
  }}

  &:hover {
    ${({ clickable }) =>
      clickable
        ? css`
            cursor: pointer;
          `
        : ''}
  }

  &:after {
    ${({ clickable, color, theme }) =>
      clickable
        ? css`
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: ${(theme as any)[color] ||
            color ||
            theme.textPrimary};
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          `
        : ''}
  }

  &:hover:after {
    ${({ clickable }) =>
      clickable
        ? css`
            transform: scaleX(1);
            transform-origin: bottom left;
          `
        : ''}
  }
`
export default Text
