import React, { useState, forwardRef } from 'react'
import Col from '../../Col/Col'
import Icon from '../../Icon/Icon'
import { Checkbox, Container, Label, Prefix } from './InputCheckboxstyle'

interface FieldCheckboxOption {
  value: string
  label: string
  children?: any
}
export interface InputCheckboxConfig {
  options: FieldCheckboxOption[]
  isPassword?: boolean
  multiple?: boolean
}

interface InputCheckboxProps extends InputCheckboxConfig {
  name: string
  value?: any
  onChange: (value?: any) => any
  hasError?: boolean
  required?: boolean
  className?: string
}

const InputCheckbox = (
  {
    name,
    value,
    onChange,
    options,
    hasError,
    required,
    className,
    multiple,
  }: InputCheckboxProps,
  ref: any
) => {
  const [isFocused, setIdFocused] = useState()
  const onOptionClick = (clickedKey: any) => {
    if (multiple) {
      const newValue =
        value?.length && typeof value === 'object' ? [...value] : []
      let index = -1
      index = newValue?.findIndex((el) => el === clickedKey)
      if (index >= 0) {
        newValue.push(clickedKey)
      }
      onChange(newValue)
    } else {
      onChange(value === clickedKey ? undefined : clickedKey)
    }
  }

  return (
    <Col>
      {options?.map((option) => {
        let checked = false
        checked = multiple
          ? !!value?.includes(option.value)
          : value === option.value
        return (
          <Container
            key={name + option.value}
            checked={checked}
            isFocused={isFocused}
            hasError={hasError}
            className={`input-bg ${className}`}
            onClick={() => onOptionClick(option.value)}
          >
            <Prefix>
              <Checkbox>{checked && <Icon icon="check" />}</Checkbox>
            </Prefix>
            <Label>{option?.label}</Label>
          </Container>
        )
      })}
    </Col>
  )
}

export default forwardRef(InputCheckbox)
