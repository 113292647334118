import React from 'react'
import {useTranslation} from 'react-i18next'
import Text, {Title, Title2} from '../../components/common/Text/Text'
import Col from '../../components/common/Col/Col'
import {View, Content} from './About.style'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

const About = () => {
    const {t} = useTranslation()

    return (
        <View>
            <Header/>
            <Content>
                <Col alignItems="center" justifyContent="center" margin="0 0 50px 0">
                    <Title textAlign="center">About us</Title>
                    <Title2 textAlign="center">Our story</Title2>
                    <Text textAlign="center">
                        Nous sommes 3 français.
                    </Text>
                    <Title2 textAlign="center">Our missions</Title2>
                    <Text textAlign="center">
                        Nous sommes 3 français.
                    </Text>
                    <Title2 textAlign="center">Our team</Title2>
                    <Text textAlign="center">
                        Nous sommes 3 français.
                    </Text>
                </Col>
            </Content>
            <Footer/>
        </View>
    )
}

export default About
