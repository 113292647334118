import React, { useMemo } from 'react'
import flash from '../../../../assets/flash.svg'
import { StringLike } from '@firebase/util'
import Text from '../../../../components/common/Text/Text'
import Row from '../../../../components/common/Row/Row'
import {
  Container,
  VoteParts,
  VotePart,
  FlashSeparator,
  Shadow,
  Informations,
  InformationTitle,
  InformationItem,
  InformationIconItemNumber,
} from './VotePreview.style'
import PoweredBy from '../../../../components/PoweredBy/PoweredBy'
export interface VotePreviewConfig {
  title?: StringLike
  elements: Array<{
    image: string
    name: string
  }>
  actions?: Array<{ '0': any; value: any; '1': any; name?: string }>
}

interface VotePreviewProps extends VotePreviewConfig {
  noRadius?: boolean
  score1: number
  score2: number
}

const VotePreview = ({
  elements,
  title,
  score1,
  score2,
  actions,
}: VotePreviewProps) => {
  return (
    <Container>
      <VoteParts>
        <VotePart src={elements?.[0]?.image}>
          <FlashSeparator src={flash} />
          <Shadow></Shadow>
        </VotePart>
        <VotePart src={elements?.[1]?.image}>
          <Shadow></Shadow>
        </VotePart>
      </VoteParts>
      <Informations>
        <InformationTitle>{title}</InformationTitle>
        <Row alignItems="center" justifyContent="center" margin="20px 0">
          <Text textAlign="center" color="textSecondary">
            Comment 1 or 2
          </Text>
        </Row>
        <InformationItem>
          <InformationIconItemNumber>1</InformationIconItemNumber>
          <Row flex="1" alignItems="center" justifyContent="space-between">
            <Text color="#FFFFFF">{elements?.[0]?.name}</Text>
            <Text color="#FFFFFF">{score1}</Text>
          </Row>
        </InformationItem>
        <InformationItem>
          <InformationIconItemNumber>2</InformationIconItemNumber>
          <Row flex="1" alignItems="center" justifyContent="space-between">
            <Text color="#FFFFFF">{elements?.[1]?.name}</Text>
            <Text color="#FFFFFF">{score2}</Text>
          </Row>
        </InformationItem>
      </Informations>
      <PoweredBy />
    </Container>
  )
}

export default VotePreview