import React, {useState} from 'react'
import Button from '../common/Button/Button'
import Icon from '../common/Icon/Icon'
import Modal, {useModal} from '../common/Modal/Modal'
import {Container, PreviewSizer, Content} from './Preview.style'

interface PreviewProps {
    children?: any
}

const Preview = ({children}: PreviewProps) => {
    const [fullScreen, setFullScreen] = useState(false)
    const previewActionsModal = useModal()

    return (
        <>
            <Container fullScreen={fullScreen}
                       onClick={() => previewActionsModal.open()}>
                <PreviewSizer/>
                <Content>{children}</Content>
            </Container>
            <Modal title="Actions" {...previewActionsModal}>
                {!fullScreen ? (
                    <Button
                        color="primary"
                        onClick={() => {
                            setFullScreen(true)
                            previewActionsModal.close()
                        }}
                        expand
                        prefix={<Icon icon="expand"/>}
                    >
                        Full screen
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        onClick={() => {
                            setFullScreen(false)
                            previewActionsModal.close()
                        }}
                        expand
                        prefix={<Icon icon="compress-alt"/>}
                    >
                        Quit full screen
                    </Button>
                )}
            </Modal>
        </>
    )
}

export default Preview
