import styled, { css } from 'styled-components'
import Icon from '../Icon/Icon'

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'default'
  | 'contrast'
  | string

interface ContainerProps {
  color?: ButtonType
  isLoading?: boolean
  expand?: boolean
}

export const ButtonContainer = styled.div<{ expand?: boolean; margin?: any }>`
  width: ${({ expand }) => (expand ? '100%' : 'unset')};
  margin: ${({ margin }) => margin};
`

export const Prefix = styled.div`
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Suffix = styled.div`
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Container = styled.button<ContainerProps>`
  height: 45px;
  min-width: 45px;
  box-sizing: border-box;
  position: relative;
  padding: 0;
  border: none;
  transition: all 0.2s cubic-bezier(0.54, 0.1, 0, 0.99);
  border-radius: ${({ theme }) => theme.borderRadius}px;
  font-weight: 700;
  ${({ theme, color = 'default' }) => {
    let bgColor = ''
    let fontColor = theme.textPrimary
    if (color === 'default') bgColor = theme.background10
    else if (color === 'contrast') {
      bgColor = theme.textPrimary
      fontColor = theme.background
    } else {
      bgColor = (theme as any)[color]
      fontColor = '#FFFFFF'
    }
    if (!bgColor) {
      bgColor = color
      fontColor = theme.textPrimary
    }
    return css`
      background-color: ${bgColor};
      box-shadow: 0px 0px 0px 0px ${bgColor};
      color: ${fontColor};
      &:hover {
        box-shadow: 0px 0px 0px 3px ${bgColor};
      }
      ${Prefix} *, ${Suffix} * {
        color: ${fontColor};
      }
    `
  }}
  cursor: ${({ isLoading, disabled }) => {
    if (isLoading) {
      return 'wait'
    } else if (disabled) {
      return 'not-allowed'
    }
    return 'pointer'
  }};
  ${({ expand }) => (expand ? 'width: 100%;' : '')};
`
export const Content = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  height: 45px;
  min-width: 45px;
  border: none;
  padding: 10px 18px;
  box-sizing: border-box;
  position: relative;
  opacity: ${({ isLoading }) => (isLoading ? '0' : '1')};
`
export const Body = styled.div`
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
`

export const Label = styled.div`
  padding: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
export const LoadingContainer = styled.div<{ isLoading?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  * {
    color: #ffffff;
  }
`

export const ButtonIcon = styled(Icon)`
  margin-right: 5px;
`
