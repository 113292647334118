import React, { useState } from 'react'
import useSession from '../../../hooks/useSession'
import Session from '../../../components/Session/Session'
import VotePreview from './VotePreview/VotePreview'

const SessionVote = () => {
  const [score1, setScore1] = useState(0)
  const [score2, setScore2] = useState(0)
  const sessionHandler = useSession({
    onLike: () => setScore1((v) => v + 1),
    onChat: () => setScore2((v) => v + 1),
  })

  const data = sessionHandler.data?.data

  return (
    <Session {...sessionHandler}>
      <VotePreview score1={score1} score2={score2} {...(data as any)} />
    </Session>
  )
}

export default SessionVote
