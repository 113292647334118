import React, { forwardRef } from 'react'
import ReactDOM from 'react-dom'
import { TriggerType, usePopperTooltip } from 'react-popper-tooltip'
import styled from 'styled-components'

const TooltipContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  box-shadow: 0px 10px 50px rgb(0 0 0 / 10%);
  z-index: 10000;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
`
const Container = styled.div``
interface TooltipProps {
  children: any
  content?: any
  trigger?: TriggerType | TriggerType[] | null
  className?: string
  placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end'
}
const Tooltip = ({
  children,
  content,
  placement = 'bottom',
  trigger = 'hover',
  className,
}: TooltipProps) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger,
      placement,
    })

  return (
    <Container ref={setTriggerRef} className={className}>
      {children}
      {visible &&
        !!content &&
        ReactDOM.createPortal(
          <TooltipContainer ref={setTooltipRef} {...getTooltipProps()}>
            {content}
          </TooltipContainer>,
          document.getElementById('modal-root') as any
        )}
    </Container>
  )
}

export default Tooltip
