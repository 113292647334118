import React from 'react'
import styled, { keyframes } from 'styled-components'
import Icon from '../Icon/Icon'

export const fadefromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`
export const CloseIcon = styled(Icon)`
  font-size: 10px;
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  backdrop-filter: blur(2px);
`

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(16, 16, 16, 0.5);
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: scroll;
`

export const Position = styled.div`
  width: 100%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.borderColor};
  min-width: 290px;
  max-width: 100%;;
  background-color: ${({ theme }) => theme.background};
  border-radius: 20px;
  position: relative;
  z-index: 10;
  animation-name: ${fadefromBottom};
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.54, 0.1, 0, 0.99);
  box-shadow: ${({ theme }) => theme.boxShadow};
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`
export const Header = styled.div<{ hasBorder: boolean }>`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  padding: 10px;
  border-bottom: 1px solid
    ${({ theme, hasBorder }) => (hasBorder ? theme.borderColor : 'transparent')};
  background-color: ${({ theme }) => theme.background};
`

export const Title = styled.h3`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  margin: 0;
`

export const CloseButton = styled.button`
  cursor: pointer;
  border: none;
  font-size: 20px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  background-color: transparent;
  border-radius: 10px;
  &:hover {
    background-color: ${({ theme }) => theme.background10};

    ${CloseIcon} {
      transform: scale(1.2);
    }
  }
`
export const Footer = styled.div`
  padding: 10px 20px;
  position: sticky;
  bottom: 0;
`
export const Body = styled.div`
  padding: 20px;
  overflow: auto;
  display: flex;
  flex: 1;
  box-sizing: border-box;
  max-height: 100%;
  flex-direction: column;

  & + ${Footer} {
    border-top: 1px solid ${({ theme }) => theme.borderColor};
  }
`
