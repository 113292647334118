import React, { useEffect, useState } from 'react'
import { User as FirebaseUser, onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase'
import { fetchUserData } from '../services/auth'
import { CountriesISO } from '../utils'
import Loader from '../components/common/Loader/Loader'
import { useQuery } from 'react-query'

interface User extends FirebaseUser {
  description?: string
  socialAccounts?: any
  speak?: CountriesISO
  to?: CountriesISO
}

interface AuthContextInterface {
  user?: User | undefined
  getUser: () => void
}

const AuthContext = React.createContext<AuthContextInterface>({} as any)

interface AuthProviderProps {
  children: any
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState(undefined as User | undefined)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userData = await fetchUserData(user.uid)
        setUser({
          ...user,
          ...userData,
        })
      } else {
        setUser(undefined)
      }
      setIsLoading(false)
    })
    return () => unsubscribe()
  }, [])

  const getUser = () => {
    setUser({ ...user, ...(auth?.currentUser as FirebaseUser) })
  }
  return (
    <AuthContext.Provider
      value={{
        user,
        getUser,
      }}
    >
      <Loader isLoading={isLoading} isFullScreen={isLoading} >
        {children}
      </Loader>
    </AuthContext.Provider>
  )
}
export default AuthContext
