import React from 'react'
import { Field } from '../FormBuilder/FormBuilder.types'
import FormItem from '../FormItem/FormItem'
import { AddButton, Container, RemoveIcon } from './FormFieldArray.style'
import Text from '../Text/Text'
import { useFieldArray } from 'react-hook-form'
import Button from '../Button/Button'
import Row from '../Row/Row'
import Col from '../Col/Col'
import Icon from '../Icon/Icon'

export interface FieldArrayConfig {
  /**
   * children - The child field you want to render
   */
  children?: Omit<Field, 'name'>
  /**
   * editable - A Boolean to decide if we have the possibility to add/delete elements
   */
  editable?: boolean
  labels?: {
    add?: string
    remove?: string
  }
}

interface FieldsArrayProps {
  config: FieldArrayConfig
  name: string
  control?: any
}

const FormFieldArray = (props: FieldsArrayProps) => {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: props.name,
  })
  return (
    <>
      {props?.config?.children &&
        fields?.map((field: any, index: number) => {
          const formItemProps: Field = {
            ...props.config.children as any,
            name: `${props?.name}.${index}`,
          }
          return (
            <Row key={field.name} flex="1">
              <Container>
                <Text>{field?.label}</Text>
                <FormItem {...formItemProps} />
              </Container>
              {props?.config?.editable && (
                <Row alignItems="center" justifyContent="flex-end">
                  <RemoveIcon icon="minus-small" onClick={() => remove(index)} />
                </Row>
              )}
            </Row>
          )
        })}
      {props?.config?.editable && (
        <Row alignItems="center" justifyContent="flex-end">
          <AddButton onClick={() => append(null as any)} color="info10">
            <Icon icon="plus-small" />
            {props?.config?.labels?.add ?? 'Add element'}
          </AddButton>
        </Row>
      )}
    </>
  )
}

export default FormFieldArray
