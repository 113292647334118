import styled from 'styled-components'

const Col = styled.div<{
  flex?: number | string
  alignItems?: string
  justifyContent?: string
  margin?: string
  minWidth?: string | number
}>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex || 'unset'};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  margin: ${({ margin }) => margin};
  min-width: ${({ minWidth }) => (minWidth ? minWidth + 'px' : undefined)};
`
export default Col
