import { useEffect, useState } from 'react'

const useScroll = () => {
  const [scroll, setScroll] = useState(window.pageYOffset)
  const handleScroll = (event: Event) => {
    setScroll(window.pageYOffset)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  return scroll
}

export default useScroll
