import React from 'react'
import { Field } from '../FormBuilder/FormBuilder.types'
import FormItem from '../FormItem/FormItem'
import { Container, Content } from './FormFieldObject.style'

export interface FieldObjectConfig {
  children?: Field[]
  style?: object
  childStyle?: object
}
interface FieldsObjectProps {
  config?: FieldObjectConfig
  name: string
}

const FormFieldObject = ({ config, name }: FieldsObjectProps) => {
  return (
    <Container style={config?.style}>
      {config?.children?.map((field: any, index: number) => (
        <Content key={field.name} style={config?.childStyle}>
          {/* {!!(index % 2) && <Separator />} */}
          <FormItem {...field} name={`${name}.${field.name}`} />
        </Content>
      ))}
    </Container>
  )
}

export default FormFieldObject
